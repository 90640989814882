import React, { Component } from 'react';
import ReactWhatsapp from 'react-whatsapp';

class Header extends Component {
  render() {
    return (
      <header className='page-head'>
        <div className='rd-navbar-wrap'>
          <nav
            data-layout='rd-navbar-fixed'
            data-sm-layout='rd-navbar-fixed'
            data-md-device-layout='rd-navbar-fixed'
            data-lg-layout='rd-navbar-static'
            data-lg-device-layout='rd-navbar-static'
            data-stick-up-clone='false'
            data-md-stick-up-offset='53px'
            data-lg-stick-up-offset='53px'
            data-md-stick-up='true'
            data-lg-stick-up='true'
            className='rd-navbar rd-navbar-corporate-dark'
          >
            <div className='rd-navbar-inner'>
              <div className='rd-navbar-aside'>
                <div
                  data-custom-toggle='.rd-navbar-aside'
                  data-custom-toggle-disable-on-blur='true'
                  className='rd-navbar-aside-toggle'
                >
                  <span></span>
                </div>
                <div className='rd-navbar-aside-content context-dark'>
                  <ul className='rd-navbar-aside-group list-units'>
                    <li>
                      <div className='unit unit-horizontal unit-spacing-xs'>
                        <div className='unit-left'>
                          <span className='icon icon-xxs-small icon-primary material-icons-place icon-shift-1 offset-top-2'></span>
                        </div>
                        <div className='unit-body'>
                          <a
                            href='#'
                            className='link-white-v2 reveal-inline font-accent'
                          >
                            SouthPort, GoldCoast, QLD 4217
                          </a>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className='unit unit-horizontal unit-spacing-xs'>
                        <div className='unit-left'>
                          <span className='icon icon-xxs icon-primary fa-clock-o offset-top-2'></span>
                        </div>
                        <div className='unit-body'>
                          <p className='text-white font-accent'>
                            Mon – Sun: 9:00am–18:00pm.
                          </p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className='unit unit-horizontal unit-spacing-xs'>
                        <div className='unit-left'>
                          <span className='icon icon-xxs icon-primary material-icons-phone icon-shift-2'></span>
                        </div>
                        <div className='unit-body'>
                          <a
                            href='callto:#'
                            className='link-white-v2 font-accent'
                          >
                            (+61) 433 470 594
                          </a>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <div className='rd-navbar-aside-group'>
                    <ul className='list-inline list-inline-reset'>
                      <li>
                        <a
                          href='https://www.facebook.com/Taxbysam-1605477543041650'
                          className='icon icon-round icon-san-juan-filled icon-xxs-smallest fa fa-facebook'
                        ></a>
                      </li>
                      <li>
                        <ReactWhatsapp number='+61-433470594' message=''>
                          <a className='icon icon-round icon-san-juan-filled icon-xxs-smallest fa fa-whatsapp' />
                        </ReactWhatsapp>
                      </li>
                      <li>
                        <a
                          href='https://www.google.com/maps/place/Tax+Today+Gold+Coast/@-27.9649783,153.4099838,17z/data=!3m1!4b1!4m5!3m4!1s0x6b910fed104153c5:0x19c4160df094901a!8m2!3d-27.9649835!4d153.4121625?authuser=2'
                          className='icon icon-round icon-san-juan-filled icon-xxs-smallest fa fa-google-plus'
                        ></a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className='rd-navbar-group rd-navbar-search-wrap'>
                <div className='rd-navbar-panel'>
                  <button
                    data-custom-toggle='.rd-navbar-nav-wrap'
                    data-custom-toggle-disable-on-blur='true'
                    className='rd-navbar-toggle'
                  >
                    <span></span>
                  </button>
                  <a href='/' className='rd-navbar-brand brand'>
                    <img
                      src={
                        require('../../assets/images/taxbysam-logo.png').default
                      }
                      alt=''
                      width='150'
                      height='27'
                    />
                  </a>
                </div>
                <div className='rd-navbar-nav-wrap'>
                  <div className='rd-navbar-nav-inner'>
                    <div className='rd-navbar-search'>
                      <form
                        action='search-results.html'
                        method='GET'
                        data-search-live='rd-search-results-live'
                        className='rd-search'
                      >
                        <div className='form-group'>
                          <label
                            for='rd-search-form-input'
                            className='form-label'
                          >
                            Search...
                          </label>
                          <input
                            id='rd-search-form-input'
                            type='text'
                            name='s'
                            autocomplete='off'
                            className='form-control'
                          />
                          <div
                            id='rd-search-results-live'
                            className='rd-search-results-live'
                          ></div>
                        </div>
                        <button
                          type='submit'
                          className='rd-search-submit'
                        ></button>
                      </form>
                      <button
                        data-rd-navbar-toggle='.rd-navbar-search, .rd-navbar-search-wrap'
                        className='rd-navbar-search-toggle'
                      ></button>
                    </div>
                    <ul className='rd-navbar-nav'>
                      <li
                        className={this.props.tab === 'index' ? 'active' : null}
                      >
                        <a href='/'>Home</a>
                      </li>
                      <li
                        className={
                          this.props.tab === 'aboutUs' ? 'active' : null
                        }
                      >
                        <a href='/about-us'>About Us</a>
                      </li>
                      <li
                        className={
                          this.props.tab === 'services' ? 'active' : null
                        }
                      >
                        <a href='/services'>Services</a>
                      </li>
                      <li
                        className={this.props.tab === 'blogs' ? 'active' : null}
                      >
                        <a href='/blogs'> Blogs</a>
                      </li>
                      {/* <li>
                        <a href='#'>Blog</a>
                        <ul className='rd-navbar-dropdown'>
                          <li>
                            <a href='blog-classNameic.html'>Classic blog</a>
                          </li>
                          <li>
                            <a href='blog-grid.html'>Grid blog</a>
                          </li>
                          <li>
                            <a href='blog-line.html'>Blog Line</a>
                          </li>
                          <li>
                            <a href='blog-masonry.html'>Masonry Blog</a>
                          </li>
                          <li>
                            <a href='blog-post.html'>Blog post</a>
                          </li>
                        </ul>
                      </li> 
                      <li>
                        <a href='#'>Pages</a>
                        <ul className='rd-navbar-megamenu'>
                          <li>
                            <h5 className='rd-megamenu-header'>Gallery</h5>
                            <ul className='rd-navbar-list'>
                              <li>
                                <a href='gallery-grid.html'>Grid gallery</a>
                              </li>
                              <li>
                                <a href='gallery-masonry.html'>
                                  Masonry gallery
                                </a>
                              </li>
                              <li>
                                <a href='gallery-cobbles.html'>
                                  Cobbles gallery
                                </a>
                              </li>
                              <li>
                                <a href='gallery-item.html'>Gallery item</a>
                              </li>
                            </ul>
                          </li>
                          <li>
                            <h5 className='rd-megamenu-header'>Pages</h5>
                            <ul className='rd-navbar-list'>
                              <li>
                                <a href='appointment.html'>
                                  Get In Touch
                                </a>
                              </li>
                              <li>
                                <a href='clients.html'>Clients</a>
                              </li>
                              <li>
                                <a href='clients-testimonials.html'>
                                  Clients testimonials
                                </a>
                              </li>
                              <li>
                                <a href='our-team.html'>Our team</a>
                              </li>
                              <li>
                                <a href='team-member-profile.html'>
                                  Team member profile
                                </a>
                              </li>
                              <li>
                                <a href='careers.html'>Careers</a>
                              </li>
                              <li>
                                <a href='pricing.html'>Pricing</a>
                              </li>
                              <li>
                                <a href='faq.html'>FAQ</a>
                              </li>
                            </ul>
                          </li>
                          <li>
                            <h5 className='rd-megamenu-header'>Extras</h5>
                            <ul className='rd-navbar-list'>
                              <li>
                                <a href='extras.html'>Extras</a>
                              </li>
                              <li>
                                <a href='404-page.html'>404 Page</a>
                              </li>
                              <li>
                                <a href='503-page.html'>503 Page</a>
                              </li>
                              <li>
                                <a href='maintenance.html'>Maintenance</a>
                              </li>
                              <li>
                                <a href='coming-soon.html'>Coming soon</a>
                              </li>
                              <li>
                                <a href='login.html'>Login</a>
                              </li>
                              <li>
                                <a href='register.html'>Register</a>
                              </li>
                              <li>
                                <a href='search-results.html'>Search results</a>
                              </li>
                              <li>
                                <a href='privacy-policy.html'>Terms of use</a>
                              </li>
                            </ul>
                          </li>
                          <li>
                            <h5 className='rd-megamenu-header'>Components</h5>
                            <ul className='rd-navbar-list'>
                              <li>
                                <a href='accordion.html'>Accordion</a>
                              </li>
                              <li>
                                <a href='buttons.html'>Buttons</a>
                              </li>
                              <li>
                                <a href='grid-system.html'>Grid</a>
                              </li>
                              <li>
                                <a href='forms.html'>Forms</a>
                              </li>
                              <li>
                                <a href='icons.html'>Icons</a>
                              </li>
                              <li>
                                <a href='icon-lists.html'>Icon lists</a>
                              </li>
                              <li>
                                <a href='progress-bars.html'>Progress bars</a>
                              </li>
                              <li>
                                <a href='tabs.html'>Tabs</a>
                              </li>
                              <li>
                                <a href='tables.html'>Table styles</a>
                              </li>
                              <li>
                                <a href='typography.html'>Typography</a>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </li> */}
                      <li
                        className={
                          this.props.tab === 'contactUs' ? 'active' : null
                        }
                      >
                        <a href='/contact-us'>Contact Us</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </header>
    );
  }
}

export default Header;
