import React, { Component } from 'react';

class Loader extends Component {
  render() {
    return (
      <div className='page-loader page-loader-variant-1'>
        <div>
          <a href='index.html' className='brand brand-md brand-inverse'>
            <img
              src={
                require('../../assets/images/taxbysam-logo-light.png').default
              }
              alt=''
              width='150'
              height='27'
            />
          </a>
          <div className='page-loader-body'>
            <div id='spinningSquaresG'>
              <div id='spinningSquaresG_1' className='spinningSquaresG'></div>
              <div id='spinningSquaresG_2' className='spinningSquaresG'></div>
              <div id='spinningSquaresG_3' className='spinningSquaresG'></div>
              <div id='spinningSquaresG_4' className='spinningSquaresG'></div>
              <div id='spinningSquaresG_5' className='spinningSquaresG'></div>
              <div id='spinningSquaresG_6' className='spinningSquaresG'></div>
              <div id='spinningSquaresG_7' className='spinningSquaresG'></div>
              <div id='spinningSquaresG_8' className='spinningSquaresG'></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Loader;
