import React, { Component } from 'react';
import Loader from '../components/loader';
import Header from '../components/header';
import Footer from '../components/footer';

class OurTeam extends Component {
  render() {
    return (
      <div className='page'>
        <Loader />
        <Header tab='index' />
        <section
          style={{
            backgroundImage: `url(${
              require('../../assets/images/bg-image-6.jpg').default
            })`,
          }}
          class='section-30 section-sm-40 section-md-66 section-lg-bottom-90 bg-gray-dark page-title-wrap'
        >
          <div class='shell'>
            <div class='page-title'>
              <h2>Our Team</h2>
            </div>
          </div>
        </section>

        <section class='section-66 section-sm-bottom-90 section-lg-bottom-130'>
          <div class='shell'>
            <div class='range'>
              <div class='cell-xs-12 text-center'>
                <h3>Team</h3>
              </div>
            </div>
            <div class='range offset-top-40'>
              <div class='cell-xs-6 cell-sm-4 cell-md-3'>
                <div class='thumbnail thumbnail-variant-1'>
                  <div class='thumbnail-image'>
                    <img
                      src={
                        require('../../assets/images/team-1-189x189.jpg')
                          .default
                      }
                      alt=''
                      width='189'
                      height='189'
                    />
                    <div class='thumbnail-image-inner'>
                      <a
                        href='callto:#'
                        class='icon icon-md material-icons-local_phone link-primary-inverse-v2'
                      ></a>
                      <a
                        href='mailto:#'
                        class='icon icon-md-smaller fa-envelope-o link-white'
                      ></a>
                    </div>
                  </div>
                  <div class='thumbnail-caption'>
                    <h5 class='header'>
                      <a href='team-member-profile.html'>Julien Miro</a>
                    </h5>
                    <p>Software Developer</p>
                  </div>
                </div>
              </div>
              <div class='cell-xs-6 cell-sm-4 cell-md-3 offset-top-60 offset-xs-top-0'>
                <div class='thumbnail thumbnail-variant-1'>
                  <div class='thumbnail-image'>
                    <img
                      src={
                        require('../../assets/images/team-2-189x189.jpg')
                          .default
                      }
                      alt=''
                      width='189'
                      height='189'
                    />
                    <div class='thumbnail-image-inner'>
                      <a
                        href='callto:#'
                        class='icon icon-md material-icons-local_phone link-primary-inverse-v2'
                      ></a>
                      <a
                        href='mailto:#'
                        class='icon icon-md-smaller fa-envelope-o link-white'
                      ></a>
                    </div>
                  </div>
                  <div class='thumbnail-caption'>
                    <h5 class='header'>
                      <a href='team-member-profile.html'>Joan Williams</a>
                    </h5>
                    <p>Support Operator</p>
                  </div>
                </div>
              </div>
              <div class='cell-xs-6 cell-sm-4 cell-md-3 offset-top-60 offset-sm-top-0'>
                <div class='thumbnail thumbnail-variant-1'>
                  <div class='thumbnail-image'>
                    <img
                      src={
                        require('../../assets/images/team-3-189x189.jpg')
                          .default
                      }
                      alt=''
                      width='189'
                      height='189'
                    />
                    <div class='thumbnail-image-inner'>
                      <a
                        href='callto:#'
                        class='icon icon-md material-icons-local_phone link-primary-inverse-v2'
                      ></a>
                      <a
                        href='mailto:#'
                        class='icon icon-md-smaller fa-envelope-o link-white'
                      ></a>
                    </div>
                  </div>
                  <div class='thumbnail-caption'>
                    <h5 class='header'>
                      <a href='team-member-profile.html'>Benedict Smith</a>
                    </h5>
                    <p>Creative Director</p>
                  </div>
                </div>
              </div>
              <div class='cell-xs-6 cell-sm-4 cell-md-3 offset-top-60 offset-md-top-0'>
                <div class='thumbnail thumbnail-variant-1'>
                  <div class='thumbnail-image'>
                    <img
                      src={
                        require('../../assets/images/team-4-189x189.jpg')
                          .default
                      }
                      alt=''
                      width='189'
                      height='189'
                    />
                    <div class='thumbnail-image-inner'>
                      <a
                        href='callto:#'
                        class='icon icon-md material-icons-local_phone link-primary-inverse-v2'
                      ></a>
                      <a
                        href='mailto:#'
                        class='icon icon-md-smaller fa-envelope-o link-white'
                      ></a>
                    </div>
                  </div>
                  <div class='thumbnail-caption'>
                    <h5 class='header'>
                      <a href='team-member-profile.html'>Madlen Green</a>
                    </h5>
                    <p>Sales Manager</p>
                  </div>
                </div>
              </div>
              <div class='cell-xs-6 cell-sm-4 cell-md-3 offset-top-60'>
                <div class='thumbnail thumbnail-variant-1'>
                  <div class='thumbnail-image'>
                    <img
                      src={
                        require('../../assets/images/team-5-189x189.jpg')
                          .default
                      }
                      alt=''
                      width='189'
                      height='189'
                    />
                    <div class='thumbnail-image-inner'>
                      <a
                        href='callto:#'
                        class='icon icon-md material-icons-local_phone link-primary-inverse-v2'
                      ></a>
                      <a
                        href='mailto:#'
                        class='icon icon-md-smaller fa-envelope-o link-white'
                      ></a>
                    </div>
                  </div>
                  <div class='thumbnail-caption'>
                    <h5 class='header'>
                      <a href='team-member-profile.html'>Hannah Lamb</a>
                    </h5>
                    <p>Graphic Designer</p>
                  </div>
                </div>
              </div>
              <div class='cell-xs-6 cell-sm-4 cell-md-3 offset-top-60'>
                <div class='thumbnail thumbnail-variant-1'>
                  <div class='thumbnail-image'>
                    <img
                      src={
                        require('../../assets/images/team-6-189x189.jpg')
                          .default
                      }
                      alt=''
                      width='189'
                      height='189'
                    />
                    <div class='thumbnail-image-inner'>
                      <a
                        href='callto:#'
                        class='icon icon-md material-icons-local_phone link-primary-inverse-v2'
                      ></a>
                      <a
                        href='mailto:#'
                        class='icon icon-md-smaller fa-envelope-o link-white'
                      ></a>
                    </div>
                  </div>
                  <div class='thumbnail-caption'>
                    <h5 class='header'>
                      <a href='team-member-profile.html'>John Miller</a>
                    </h5>
                    <p>Junior UX Designer</p>
                  </div>
                </div>
              </div>
              <div class='cell-xs-6 cell-sm-4 cell-md-3 offset-top-60'>
                <div class='thumbnail thumbnail-variant-1'>
                  <div class='thumbnail-image'>
                    <img
                      src={
                        require('../../assets/images/team-7-189x189.jpg')
                          .default
                      }
                      alt=''
                      width='189'
                      height='189'
                    />
                    <div class='thumbnail-image-inner'>
                      <a
                        href='callto:#'
                        class='icon icon-md material-icons-local_phone link-primary-inverse-v2'
                      ></a>
                      <a
                        href='mailto:#'
                        class='icon icon-md-smaller fa-envelope-o link-white'
                      ></a>
                    </div>
                  </div>
                  <div class='thumbnail-caption'>
                    <h5 class='header'>
                      <a href='team-member-profile.html'>Tom Park</a>
                    </h5>
                    <p>Executive Director</p>
                  </div>
                </div>
              </div>
              <div class='cell-xs-6 cell-sm-4 cell-md-3 offset-top-60'>
                <div class='thumbnail thumbnail-variant-1'>
                  <div class='thumbnail-image'>
                    <img
                      src={
                        require('../../assets/images/team-8-189x189.jpg')
                          .default
                      }
                      alt=''
                      width='189'
                      height='189'
                    />
                    <div class='thumbnail-image-inner'>
                      <a
                        href='callto:#'
                        class='icon icon-md material-icons-local_phone link-primary-inverse-v2'
                      ></a>
                      <a
                        href='mailto:#'
                        class='icon icon-md-smaller fa-envelope-o link-white'
                      ></a>
                    </div>
                  </div>
                  <div class='thumbnail-caption'>
                    <h5 class='header'>
                      <a href='team-member-profile.html'>Nora Russell</a>
                    </h5>
                    <p>Account Manager</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class='shell offset-top-60 offset-sm-top-90'>
            <div class='range'>
              <div class='cell-xs-12 text-center'>
                <h3>Our Team</h3>
              </div>
            </div>
            <div class='range offset-top-40'>
              <div class='cell-xs-6 cell-sm-4 cell-md-3'>
                <div class='thumbnail-variant-2-wrap'>
                  <div class='thumbnail thumbnail-variant-2'>
                    <figure class='thumbnail-image'>
                      <img
                        src={
                          require('../../assets/images/team-9-246x300.jpg')
                            .default
                        }
                        alt=''
                        width='246'
                        height='300'
                      />
                    </figure>
                    <div class='thumbnail-inner'>
                      <div class='link-group'>
                        <span class='icon icon-xxs icon-primary material-icons-local_phone'></span>
                        <a href='callto:#' class='link-white'>
                          +1 (409) 987–5874
                        </a>
                      </div>
                      <div class='link-group'>
                        <span class='icon icon-xxs icon-primary fa-envelope-o'></span>
                        <a href='mailto:#' class='link-white'>
                          info@demolink.org
                        </a>
                      </div>
                    </div>
                    <div class='thumbnail-caption'>
                      <p class='text-header'>
                        <a href='team-member-profile.html'>Amanda Smith</a>
                      </p>
                      <div class='divider divider-md bg-white-02'></div>
                      <p class='text-caption'>Insolvency Practitioner</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class='cell-xs-6 cell-sm-4 cell-md-3 offset-top-40 offset-xs-top-0'>
                <div class='thumbnail-variant-2-wrap'>
                  <div class='thumbnail thumbnail-variant-2'>
                    <figure class='thumbnail-image'>
                      <img
                        src={
                          require('../../assets/images/team-10-246x300.jpg')
                            .default
                        }
                        alt=''
                        width='246'
                        height='300'
                      />
                    </figure>
                    <div class='thumbnail-inner'>
                      <div class='link-group'>
                        <span class='icon icon-xxs icon-primary material-icons-local_phone'></span>
                        <a href='callto:#' class='link-white'>
                          +1 (409) 987–5874
                        </a>
                      </div>
                      <div class='link-group'>
                        <span class='icon icon-xxs icon-primary fa-envelope-o'></span>
                        <a href='mailto:#' class='link-white'>
                          info@demolink.org
                        </a>
                      </div>
                    </div>
                    <div class='thumbnail-caption'>
                      <p class='text-header'>
                        <a href='team-member-profile.html'>David Nicholson</a>
                      </p>
                      <div class='divider divider-md bg-white-02'></div>
                      <p class='text-caption'>Tax Advisor</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class='cell-xs-6 cell-sm-4 cell-md-3 offset-top-40 offset-sm-top-0'>
                <div class='thumbnail-variant-2-wrap'>
                  <div class='thumbnail thumbnail-variant-2'>
                    <figure class='thumbnail-image'>
                      <img
                        src={
                          require('../../assets/images/team-11-246x300.jpg')
                            .default
                        }
                        alt=''
                        width='246'
                        height='300'
                      />
                    </figure>
                    <div class='thumbnail-inner'>
                      <div class='link-group'>
                        <span class='icon icon-xxs icon-primary material-icons-local_phone'></span>
                        <a href='callto:#' class='link-white'>
                          +1 (409) 987–5874
                        </a>
                      </div>
                      <div class='link-group'>
                        <span class='icon icon-xxs icon-primary fa-envelope-o'></span>
                        <a href='mailto:#' class='link-white'>
                          info@demolink.org
                        </a>
                      </div>
                    </div>
                    <div class='thumbnail-caption'>
                      <p class='text-header'>
                        <a href='team-member-profile.html'>Vanessa Ives</a>
                      </p>
                      <div class='divider divider-md bg-white-02'></div>
                      <p class='text-caption'>Forensic Accounting</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class='cell-xs-6 cell-sm-4 cell-md-3 offset-top-40 offset-md-top-0'>
                <div class='thumbnail-variant-2-wrap'>
                  <div class='thumbnail thumbnail-variant-2'>
                    <figure class='thumbnail-image'>
                      <img
                        src={
                          require('../../assets/images/team-12-246x300.jpg')
                            .default
                        }
                        alt=''
                        width='246'
                        height='300'
                      />
                    </figure>
                    <div class='thumbnail-inner'>
                      <div class='link-group'>
                        <span class='icon icon-xxs icon-primary material-icons-local_phone'></span>
                        <a href='callto:#' class='link-white'>
                          +1 (409) 987–5874
                        </a>
                      </div>
                      <div class='link-group'>
                        <span class='icon icon-xxs icon-primary fa-envelope-o'></span>
                        <a href='mailto:#' class='link-white'>
                          info@demolink.org
                        </a>
                      </div>
                    </div>
                    <div class='thumbnail-caption'>
                      <p class='text-header'>
                        <a href='team-member-profile.html'>Sam Kromstain</a>
                      </p>
                      <div class='divider divider-md bg-white-02'></div>
                      <p class='text-caption'>Office Manager</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}

export default OurTeam;
