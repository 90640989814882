import React, { Component } from 'react';
import Header from '../components/header';
import Footer from '../components/footer';
import Loader from '../components/loader';
import ReactWhatsapp from 'react-whatsapp';

class Services extends Component {
  render() {
    return (
      <div>
        <Loader />
        <Header tab='services' />
        <section
          style={{
            backgroundImage: `url(${
              require('../../assets/images/bg-image-2.jpg').default
            })`,
          }}
          class='section-30 section-sm-40 section-md-66 section-lg-bottom-90 bg-gray-dark page-title-wrap'
        >
          <div class='shell'>
            <div class='page-title'>
              <h2>Services</h2>
            </div>
          </div>
        </section>

        <section
          style={{
            backgroundImage: `url(${
              require('../../assets/images/services-1.jpg').default
            }`,
          }}
          class='section-60 section-sm-90 section-sm-bottom-100 bg-white bg-image bg-image-1'
        >
          <div class='shell'>
            <div class='range range-xs-center range-sm-right'>
              <div class='cell-sm-8 cell-md-6 cell-lg-5'>
                <h3>Expert Solutions</h3>
                <div class='offset-top-40'>
                  <p class='text-gray-darker'>
                    We are confident that with the wealth of extensive financial
                    expertise and firsthand commercial expertise, we have the
                    knowledge, skills and resources to deliver powerful
                    solutions that add real, tangible value to every stage of
                    your business development. In providing effective solutions,
                    we offer you a high level of Partner contact. In all areas
                    of the accountancy practice, we provide clients with
                    pragmatic, personalised advice and insights that help them
                    succeed. a
                  </p>
                  {/* <a href='our-team.html' class='btn btn-primary'>
                    Read more
                  </a> */}
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <section class='section-60 section-sm-90 bg-cello'>
          <div class='shell'>
            <div class='range'>
              <div class='cell-xs-12'>
                <h3>Our Clients</h3>
              </div>
            </div>
            <div class='range offset-top-40'>
              <div class='cell-sm-7 cell-md-5 cell-lg-4'>
                <div class='owl-carousel-inverse'>
                  <div
                    data-items='1'
                    data-stage-padding='0'
                    data-loop='true'
                    data-margin='30'
                    data-mouse-drag='false'
                    data-nav='true'
                    class='owl-carousel owl-nav-bottom-left'
                  >
                    <div class='item'>
                      <p class='text-white-05'>
                        We work closely with our diverse client base to provide
                        exceptional one-on-one service. Among our clients there
                        are many world-renowned industry leaders.
                      </p>
                    </div>
                    <div class='item'>
                      <p class='text-white-05'>
                        Got really enjoyable experience with your product. Easy
                        set up is a huge plus. I even didn’t turn to support
                        service.
                      </p>
                    </div>
                    <div class='item'>
                      <p class='text-white-05'>
                        I’m extremely satisfied with their work. My new website
                        looks great and runs smoothly. The support is excellent
                        too.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class='cell-md-7 cell-lg-8 offset-top-40 offset-md-top-0'>
                <div class='range shift-md-top-1'>
                  <div class='cell-xs-6 cell-sm-3'>
                    <div class='link-image-wrap'>
                      <a href='#' class='link-image'>
                        <img
                          src={
                            require('../../assets/images/services-2-132x83.png')
                              .default
                          }
                          alt=''
                          width='132'
                          height='83'
                        />
                      </a>
                    </div>
                  </div>
                  <div class='cell-xs-6 cell-sm-3 offset-top-30 offset-xs-top-0'>
                    <div class='link-image-wrap'>
                      <a href='#' class='link-image'>
                        <img
                          src={
                            require('../../assets/images/services-3-169x68.png')
                              .default
                          }
                          alt=''
                          width='169'
                          height='68'
                        />
                      </a>
                    </div>
                  </div>
                  <div class='cell-xs-6 cell-sm-3 offset-top-30 offset-sm-top-0'>
                    <div class='link-image-wrap'>
                      <a href='#' class='link-image'>
                        <img
                          src={
                            require('../../assets/images/services-4-141x88.png')
                              .default
                          }
                          alt=''
                          width='141'
                          height='88'
                        />
                      </a>
                    </div>
                  </div>
                  <div class='cell-xs-6 cell-sm-3 offset-top-30 offset-sm-top-0'>
                    <div class='link-image-wrap'>
                      <a href='#' class='link-image'>
                        <img
                          src={
                            require('../../assets/images/services-5-138x55.png')
                              .default
                          }
                          alt=''
                          width='138'
                          height='55'
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        <section class='section-60 section-sm-90'>
          <div class='shell'>
            <h3 class='text-center'>Tax Services</h3>
            <p class='text-center text-big'>
              We offer a full range of tax planning, preparation and support
              services.
            </p>
            <div class='range range-xs-center offset-top-45 text-center text-md-left'>
              <div class='cell-sm-10 cell-md-4'>
                <img
                  src={
                    require('../../assets/images/individual-tax-returns.jpg')
                      .default
                  }
                  alt=''
                  width='370'
                  height='220'
                />
                <div class='offset-top-22'>
                  <h5>Individual Tax Returns</h5>
                </div>
                <div class='offset-top-10'>
                  <p class='text-gray-base-05'>
                    We are committed to helping our clients maintain accurate
                    records and ensure you don’t pay more tax than you have to.
                    Our specialist tax advisors will help you minimise your tax
                    liabilities, while complying with relevant Federal and State
                    Legislation.
                  </p>
                </div>
              </div>
              <div class='cell-sm-10 cell-md-4'>
                <img
                  src={
                    require('../../assets/images/business-tax-returns.jpg')
                      .default
                  }
                  alt=''
                  width='370'
                  height='220'
                />
                <div class='offset-top-22'>
                  <h5>Business Tax Returns</h5>
                </div>
                <div class='offset-top-10'>
                  <p class='text-gray-base-05'>
                    Irrespective of the business structure you use (Sole
                    Propreitorship, Partnership, Company, Trust) You must lodge
                    a tax return for any year in which you carry on a business.
                    We work out your individual or business income tax based on
                    your taxable income using a formula. We have the knowledge,
                    expertise and empathy to sit with you and work out what is
                    best for you and your business.
                  </p>
                </div>
              </div>
              <div class='cell-sm-10 cell-md-4 offset-top-60 offset-md-top-0'>
                <img
                  src={require('../../assets/images/bas-returns.jpg').default}
                  alt=''
                  width='370'
                  height='220'
                />
                <div class='offset-top-22'>
                  <h5>BAS Returns (GST)</h5>
                </div>
                <div class='offset-top-10'>
                  <p class='text-gray-base-05'>
                    Goods and services tax (GST) is a broad-based tax of 10% on
                    most goods, services and other items sold or consumed in
                    Australia. As a GST-registered business, you need to issue
                    tax invoices to your customers, collect GST and send it to
                    the ATO with your business activity statement (BAS).
                  </p>
                </div>
              </div>
            </div>
            <div class='range range-xs-center offset-top-45 text-center text-md-left'>
              <div class='cell-sm-10 cell-md-4'>
                <img
                  src={
                    require('../../assets/images/new-business-setup.jpg')
                      .default
                  }
                  alt=''
                  width='370'
                  height='220'
                />
                <div class='offset-top-22'>
                  <h5>New Business Setup & Entity Selection</h5>
                </div>
                <div class='offset-top-10'>
                  <p class='text-gray-base-05'>
                    The four main business structures commonly used by small
                    businesses in Australia are: · Sole trader: an individual
                    trading on their own · Partnership: an association of people
                    or entities running a business together, but not as a
                    company · Trust: an entity that holds property or income for
                    the benefit of others · Company: a legal entity separate
                    from its shareholders. When deciding upon a structure for
                    your business, choose the one that best suits your business
                    needs and remember that there are advantages and
                    disadvantages for each. Choosing your business structure is
                    an important decision that can determine the licenses you
                    will need to operate, so you need to investigate each option
                    carefully. It is important to note that you can change your
                    business structure through the life of your business. As
                    your business grows and expands you may decide to change
                    your business structure, or to restructure your business.
                    Obtaining legal or other professional advice can help you
                    understand your own particular circumstances. Speak to your
                    accountant or use our Advisor Finder tool when deciding on
                    your business structure and type. It is important to
                    determine your business structure and type of business
                    before you register a business or company as the steps may
                    differ.
                  </p>
                </div>
              </div>
              <div class='cell-sm-10 cell-md-4 offset-top-60 offset-md-top-0'>
                <img
                  src={
                    require('../../assets/images/cash-management.jpg').default
                  }
                  alt=''
                  width='370'
                  height='220'
                />
                <div class='offset-top-22'>
                  <h5>Cash Management</h5>
                </div>
                <div class='offset-top-10'>
                  <p class='text-gray-base-05'>
                    Successful cash management involves not only avoiding
                    insolvency (and therefore bankruptcy), but also reducing
                    days in account receivables (AR), increasing collection
                    rates, selecting appropriate short-term investment vehicles,
                    and increasing days cash on hand all in order to improve a
                    company's overall financial profitability.
                  </p>
                </div>
              </div>
              {/* <div class='cell-sm-10 cell-md-4 offset-top-60 offset-md-top-0'>
                <img
                  src={
                    require('../../assets/images/services-8-370x220.jpg')
                      .default
                  }
                  alt=''
                  width='370'
                  height='220'
                />
                <div class='offset-top-22'>
                  <h5>IRS Audit Representation</h5>
                </div>
                <div class='offset-top-10'>
                  <p class='text-gray-base-05'>
                    If you have been selected for an IRS audit, professional
                    representation can set your mind at ease. We’ll take the
                    lead in dealing with the tax authorities based on our
                    familiarity with their...
                  </p>
                </div>
              </div> */}
            </div>
          </div>
        </section>

        <section class='section-60 section-sm-100 bg-athens-gray'>
          <div class='shell text-center text-md-left'>
            <div class='range range-md-middle range-md-center'>
              <div class='cell-md-8 cell-lg-7'>
                <h3 class='h3-variant-2'>
                  How to Choose the Right Tax Advisor
                </h3>
              </div>
              <div class='cell-md-4 cell-lg-3 offset-top-30 offset-md-top-0'>
                <ReactWhatsapp
                  class='btn btn-lg btn-primary'
                  number='+61-433470594'
                  message=''
                >
                  {' '}
                  WhatsApp Now{' '}
                </ReactWhatsapp>
              </div>
            </div>
          </div>
        </section>

        <section
          data-on='false'
          data-md-on='true'
          style={{
            backgroundImage: `url(${
              require('../../assets/images/clients-testimonials-parallax-1.jpg')
                .default
            })`,
          }}
          class='rd-parallax bg-gray-base bg-image'
        >
          <div
            data-speed='0.33'
            data-type='media'
            data-url='images/clients-testimonials-parallax-1.jpg'
            class='rd-parallax-layer'
          ></div>
          <div data-speed='0' data-type='html' class='rd-parallax-layer'>
            <div class='section-60 section-sm-90 bg-overlay-cello'>
              <div class='shell'>
                <div class='range'>
                  <div class='cell-xs-12 text-center'>
                    <h3>What We Offer</h3>
                    <p class='text-white text-big'>
                      Tax consultancy services for businesses and individuals.
                    </p>
                  </div>
                </div>
                <div class='range offset-top-40'>
                  <div class='cell-xs-6 cell-md-4'>
                    <article class='icon-box-vertical icon-box-vertical-sm'>
                      <div class='unit unit-vertical unit-spacing-sm'>
                        <div class='unit-left'>
                          <span class='icon icon-primary icon-lg fl-puppets-users30'></span>
                        </div>
                        <div class='unit-body'>
                          <h5>Tax Consultancy Services</h5>
                          <p class='text-white-05'>
                            We provide a comprehensive range of specialist tax
                            and advisory support across all of the major taxes.{' '}
                          </p>
                        </div>
                      </div>
                    </article>
                  </div>
                  <div class='cell-xs-6 cell-md-4 offset-top-50 offset-xs-top-0'>
                    <article class='icon-box-vertical icon-box-vertical-sm'>
                      <div class='unit unit-vertical unit-spacing-sm'>
                        <div class='unit-left'>
                          <span class='icon icon-primary icon-lg fl-puppets-maths26'></span>
                        </div>
                        <div class='unit-body'>
                          <h5>Bookkeeping</h5>
                          <p class='text-white-05'>
                            We’ll create and maintain a chart of accounts to
                            capture all of your financial transactions, provide
                            the right information to manage your business.
                          </p>
                        </div>
                      </div>
                    </article>
                  </div>
                  {/* <div class='cell-xs-6 cell-md-4 offset-top-50 offset-md-top-0'>
                    <article class='icon-box-vertical icon-box-vertical-sm'>
                      <div class='unit unit-vertical unit-spacing-sm'>
                        <div class='unit-left'>
                          <span class='icon icon-primary icon-lg fl-puppets-money163'></span>
                        </div>
                        <div class='unit-body'>
                          <h5>Tax Enquiry Fee Protection Insurance</h5>
                          <p class='text-white-05'>
                            The cost of a tax investigation can be very
                            expensive and thus the advantage of being adequately
                            covered for this is more important now than ever.
                          </p>
                        </div>
                      </div>
                    </article>
                  </div> */}
                  <div class='cell-xs-6 cell-md-4 offset-top-50 offset-lg-top-60'>
                    <article class='icon-box-vertical icon-box-vertical-sm'>
                      <div class='unit unit-vertical unit-spacing-sm'>
                        <div class='unit-left'>
                          <span class='icon icon-primary icon-lg fl-puppets-calendar184'></span>
                        </div>
                        <div class='unit-body'>
                          <h5>Tax Preparation</h5>
                          <p class='text-white-05'>
                            To maximise your tax refund, we have a list of
                            different occupations and deductions, prepared by
                            our qualified and experienced accoutants to maximise
                            your tax refund.{' '}
                          </p>
                        </div>
                      </div>
                    </article>
                  </div>
                  {/* Todo- crypto content pending
                  <div class='cell-xs-6 cell-md-4 offset-top-50 offset-lg-top-60'>
                    <article class='icon-box-vertical icon-box-vertical-sm'>
                      <div class='unit unit-vertical unit-spacing-sm'>
                        <div class='unit-left'>
                          <span class='icon icon-primary icon-lg fl-puppets-bank17'></span>
                        </div>
                        <div class='unit-body'>
                          <h5>Bank Reconciliation</h5>
                          <p class='text-white-05'>
                            Our bank reconciliation services ensure that every
                            deposit and withdrawal aligns with entries into your
                            accounting records.{' '}
                          </p>
                        </div>
                      </div>
                    </article>
                  </div> */}
                  <div class='cell-xs-6 cell-md-4 offset-top-50 offset-lg-top-60'>
                    <article class='icon-box-vertical icon-box-vertical-sm'>
                      <div class='unit unit-vertical unit-spacing-sm'>
                        <div class='unit-left'>
                          <span class='icon icon-primary icon-lg fl-puppets-profiles-avatar'></span>
                        </div>
                        <div class='unit-body'>
                          <h5>Individual Consultation</h5>
                          <p class='text-white-05'>
                            When you use our professional accounting services,
                            you get more than a collection of records and
                            reports. You get the benefit of our skills,
                            experience, and expertise.{' '}
                          </p>
                        </div>
                      </div>
                    </article>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}

export default Services;
