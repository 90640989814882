import React, { Component } from 'react';
import Loader from '../components/loader';
import Header from '../components/header';
import Footer from '../components/footer';

class IndexPage extends Component {
  render() {
    return (
      <div>
        <div className='page'>
          <Loader />
          <Header tab='index' />

          <section>
            <div
              data-loop='false'
              data-autoplay='false'
              data-simulate-touch='true'
              className='swiper-container swiper-slider swiper-variant-1 bg-gray-base'
            >
              <div className='swiper-wrapper text-center'>
                <div
                  data-slide-bg={
                    require('../../assets/images/home-slider-1-slide-1.jpg')
                      .default
                  }
                  className='swiper-slide'
                >
                  <div className='swiper-slide-caption'>
                    <div className='shell'>
                      <div className='range range-sm-center range-lg-left text-lg-left'>
                        <div className='cell-sm-11 cell-md-10 cell-lg-6'>
                          <h2
                            data-caption-animate='fadeInUp'
                            data-caption-delay='0s'
                            className='slider-header'
                          >
                            Our Clients Don't Have Tax Problems
                          </h2>
                          <div
                            data-caption-animate='fadeInUp'
                            data-caption-delay='350s'
                            className='offset-top-5 slider-text'
                          >
                            <p className='text-big-22 text-white font-accent text-light'>
                              Optimize tax costs using our years of experience
                            </p>
                          </div>
                          <div
                            data-caption-animate='fadeInUp'
                            data-caption-delay='450s'
                            className='offset-top-30 offset-sm-top-45'
                          >
                            <a
                              href='/appointment'
                              className='btn btn-icon btn-icon-right btn-cello'
                            >
                              <span className='icon icon-xs-smaller fa-angle-right'></span>
                              Get In Touch
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  data-slide-bg={
                    require('../../assets/images/home-slider-1-slide-2.jpg')
                      .default
                  }
                  className='swiper-slide'
                >
                  <div className='swiper-slide-caption'>
                    <div className='shell'>
                      <div className='range range-sm-center'>
                        <div className='cell-sm-11 cell-md-10 cell-lg-12'>
                          <h2
                            data-caption-animate='fadeInUp'
                            data-caption-delay='0s'
                            className='slider-header'
                          >
                            We Provide Proper Tax Solutions
                          </h2>
                          <div
                            data-caption-animate='fadeInUp'
                            data-caption-delay='350s'
                            className='offset-top-5 slider-text'
                          >
                            <p className='text-big-22 text-white font-accent text-light'>
                              Optimize tax costs using our years of experience
                            </p>
                          </div>
                          <div
                            data-caption-animate='fadeInUp'
                            data-caption-delay='450s'
                            className='offset-top-30 offset-sm-top-45'
                          >
                            <a
                              href='/appointment'
                              className='btn btn-icon btn-icon-right btn-primary'
                            >
                              <span className='icon icon-xs-smaller fa-angle-right'></span>
                              Get In Touch
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='swiper-scrollbar veil-lg'></div>
              <div className='swiper-nav-wrap veil reveal-lg-block'>
                <div className='swiper-button-prev'></div>
                <div className='swiper-button-next'></div>
              </div>
            </div>
          </section>

          <section className='section-50 section-sm-90'>
            <div className='shell text-center'>
              <h3>Our Services</h3>
              <p className='text-big font-accent text-spacing-40'>
                We provide expert tax and advisory services to individuals and
                small businesses.
              </p>
              <div className='range range-xs-center offset-top-40'>
                <div className='cell-xs-10 cell-sm-6 cell-md-4 height-fill'>
                  <article className='icon-box'>
                    <div className='box-top'>
                      <div className='box-icon'>
                        <span className='icon icon-primary icon-lg fl-puppets-chart6'></span>
                      </div>
                      <div className='box-header'>
                        <h5>
                          <a href='#'>Tax Planning</a>
                        </h5>
                      </div>
                    </div>
                    <div className='divider'></div>
                    <div className='box-body'>
                      <p>
                        Planning is the key to successfully and legally reducing
                        your tax liability.
                      </p>
                    </div>
                  </article>
                </div>
                <div className='cell-xs-10 cell-sm-6 cell-md-4 height-fill offset-top-40 offset-sm-top-0'>
                  <article className='icon-box'>
                    <div className='box-top'>
                      <div className='box-icon'>
                        <span className='icon icon-primary icon-lg fl-puppets-maths26'></span>
                      </div>
                      <div className='box-header'>
                        <h5>
                          <a href='#'>Bookkeeping</a>
                        </h5>
                      </div>
                    </div>
                    <div className='divider'></div>
                    <div className='box-body'>
                      <p>
                        We’ll create a chart of accounts to capture all your
                        financial transactions.
                      </p>
                    </div>
                  </article>
                </div>
                <div className='cell-xs-10 cell-sm-6 cell-md-4 height-fill offset-top-40 offset-md-top-0'>
                  <article className='icon-box'>
                    <div className='box-top'>
                      <div className='box-icon'>
                        <span className='icon icon-primary icon-lg fl-puppets-bank17'></span>
                      </div>
                      <div className='box-header'>
                        <h5>
                          <a href='#'>Bank Reconciliation</a>
                        </h5>
                      </div>
                    </div>
                    <div className='divider'></div>
                    <div className='box-body'>
                      <p>
                        Every deposit and withdrawal aligns with entries into
                        your accounting records.
                      </p>
                    </div>
                  </article>
                </div>
              </div>
            </div>
          </section>

          <section
            style={{
              backgroundImage: `url(${
                require('../../assets/images/home-6.png').default
              })`,
            }}
            className='section-75 section-bottom-60 section-sm-100 bg-white bg-image'
          >
            <div className='shell'>
              <div className='range range-condensed range-xs-center range-sm-right range-xs-middle'>
                <div
                  style={{ maxWidth: '430px' }}
                  className='cell-xs-10 cell-sm-7 cell-md-6 cell-lg-5'
                >
                  <div className='box bg-cello section-relative'>
                    <h3 className='text-white'>About Us</h3>
                    <div className='offset-top-40'>
                      <p className='text-white'>
                        We are dedicated to providing professional service with
                        the highest degree of honesty and integrity, and strive
                        to add value to our tax and consulting services.
                      </p>
                    </div>
                    <div className='offset-top-40'>
                      <ul className='list-marked'>
                        <li className='text-white'>Highly Competent</li>
                        <li className='text-white'>Affordable Prices</li>
                        <li className='text-white'>High Successful Recovery</li>
                      </ul>
                    </div>
                    <div className='offset-top-45'>
                      <a
                        href='/appointment'
                        className='btn btn-icon btn-icon-right btn-white-outline'
                      >
                        <span className='icon icon-xs-smaller fa-angle-right text-primary'></span>
                        Get In Touch
                      </a>
                    </div>
                  </div>
                </div>
                <div className='cell-xs-10 cell-sm-4 cell-md-3 cell-lg-2 offset-top-40 offset-sm-top-0 box-list-xs'>
                  {/* <div className='counter-boxed box-xs bg-cello text-center'>
                    <div className='counter'>23</div>
                    <div className='offset-top-5'>
                      <p className='text-bismark text-sbold'>Consultants</p>
                    </div>
                  </div> */}
                  <div className='counter-boxed box-xs bg-cello text-center'>
                    <div className='counter'>500+</div>
                    <div className='offset-top-5'>
                      <p className='text-bismark text-sbold'>Happy Clients</p>
                    </div>
                  </div>
                  <div className='counter-boxed box-xs bg-cello text-center'>
                    <div className='counter'>10+</div>
                    <div className='offset-top-5'>
                      <p
                        style={{ lineHeight: 1.2 }}
                        className='text-bismark text-sbold'
                      >
                        Years of Experience
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section
            style={{
              backgroundImage: `url(${
                require('../../assets/images/home-5.jpg').default
              })`,
            }}
            className='bg-image bg-image-md-inverse-transparent'
          >
            <div className='bg-overlay-lighten-inverse-md'>
              <div className='shell section-66 section-sm-100'>
                <div className='range range-xs-center range-sm-left'>
                  <div className='cell-xs-10 cell-sm-8 cell-md-6'>
                    <div className='unit unit-middle unit-horizontal unit-spacing-sm'>
                      <div className='unit-left'>
                        <span className='icon icon-lg fl-puppets-maths27 text-gray'></span>
                      </div>
                      <div className='unit-body'>
                        <h3>Tax Calculator</h3>
                      </div>
                    </div>
                    <div
                      data-caption-animate='fadeInUp'
                      data-caption-delay='450s'
                      className='offset-top-30 offset-sm-top-45'
                    >
                      <a
                        href='https://www.ato.gov.au/Calculators-and-tools/Host/?anchor=IncomeTaxEstimator/questions&anchor=IncomeTaxEstimator&anchor=IncomeTaxEstimator/questions#IncomeTaxEstimator/questions'
                        className='btn btn-icon btn-icon-right btn-cello'
                      >
                        <span className='icon icon-xs-smaller fa-angle-right'></span>
                        Estimate Your Tax Here...
                      </a>
                    </div>
                    {/* <form className='range range-xs-center range-sm-left inset-lg-right-30'>
                      <div className='cell-sm-6'>
                        <div className='form-group form-group-outside'>
                          <select
                            id='index-business'
                            data-minimum-results-for-search='Infinity'
                            className='form-control select-filter'
                          >
                            <option value='1'>Retail</option>
                            <option value='2'>All</option>
                          </select>
                          <label
                            htmlFor='index-business'
                            className='form-label form-label-outside'
                          >
                            Choose your business area
                          </label>
                        </div>
                      </div>
                      <div className='cell-sm-6 offset-top-30 offset-sm-top-0'>
                        <div className='form-group form-group-outside'>
                          <select
                            id='index-residence'
                            data-minimum-results-for-search='Infinity'
                            className='form-control select-filter'
                          >
                            <option value='2'>US Residence</option>
                            <option value='3'>All</option>
                          </select>
                          <label
                            htmlFor='index-residence'
                            className='form-label form-label-outside'
                          >
                            Country of residence
                          </label>
                        </div>
                      </div>
                      <div className='cell-sm-6 offset-top-30 offset-sm-top-45'>
                        <div className='form-group form-group-outside'>
                          <select
                            id='index-employees'
                            data-minimum-results-for-search='Infinity'
                            className='form-control select-filter'
                          >
                            <option value='2'>1-5</option>
                            <option value='3'>1-6</option>
                          </select>
                          <label
                            htmlFor='index-employees'
                            className='form-label form-label-outside'
                          >
                            Employees number
                          </label>
                        </div>
                      </div>
                      <div className='cell-sm-6 offset-top-30 offset-sm-top-45'>
                        <div className='form-group form-group-outside'>
                          <input
                            id='index-income'
                            type='text'
                            name='name'
                            className='form-control bg-whisper-lighten'
                          />
                          <label
                            htmlFor='index-income'
                            className='form-label form-label-outside'
                          >
                            Yearly Income
                          </label>
                        </div>
                      </div>
                      <div className='cell-sm-6 offset-top-30 offset-sm-top-45'>
                        <div className='form-group form-group-outside'>
                          <input
                            id='index-total'
                            type='text'
                            name='name'
                            className='form-control form-border-bottom bg-whisper-lighten'
                          />
                          <label
                            htmlFor='index-total'
                            className='form-label form-label-outside'
                          >
                            Total Payment
                          </label>
                        </div>
                      </div>
                    </form> */}
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className='section-top-60 section-sm-top-90 bg-whisper-lighten'>
            <div className='shell text-center'>
              <div className='range range-sm-center'>
                <div className='cell-xs-12'>
                  <h3>Testimonials</h3>
                </div>
                <div className='cell-sm-11 cell-md-10 cell-lg-8 offset-top-40 offset-sm-top-50'>
                  <div className='slick-carousel-complex-variant-1'>
                    <div
                      id='child-carousel'
                      data-for='.carousel-parent'
                      data-arrows='false'
                      data-loop='true'
                      data-dots='false'
                      data-swipe='true'
                      data-items='1'
                      data-xs-items='3'
                      data-sm-items='3'
                      data-md-items='3'
                      data-lg-items='3'
                      data-slide-to-scroll='1'
                      data-center-mode='true'
                      data-center-padding='0'
                      className='slick-slider slick-slider-images slick-slider'
                    >
                      <div className='item'>
                        <img
                          src={
                            require('../../assets/images/clients-testimonials-4-153x153.jpg')
                              .default
                          }
                          alt=''
                          width='153'
                          height='153'
                        />
                      </div>
                      <div className='item'>
                        <img
                          src={
                            require('../../assets/images/clients-testimonials-5-153x153.jpg')
                              .default
                          }
                          alt=''
                          width='153'
                          height='153'
                        />
                      </div>
                      <div className='item'>
                        <img
                          src={
                            require('../../assets/images/clients-testimonials-6-153x153.jpg')
                              .default
                          }
                          alt=''
                          width='153'
                          height='153'
                        />
                      </div>
                      <div className='item'>
                        <img
                          src={
                            require('../../assets/images/clients-testimonials-3-153x153.jpg')
                              .default
                          }
                          alt=''
                          width='153'
                          height='153'
                        />
                      </div>
                    </div>
                    <div
                      data-arrows='false'
                      data-loop='true'
                      data-dots='true'
                      data-swipe='true'
                      data-child='#child-carousel'
                      data-for='#child-carousel'
                      data-items='1'
                      data-xs-items='1'
                      data-sm-items='1'
                      data-md-items='1'
                      data-lg-items='1'
                      data-slide-to-scroll='1'
                      data-center-mode='true'
                      className='slick-slider carousel-parent slick-dots-variant-1 offset-top-40'
                    >
                      <div className='item inset-left-10 inset-right-10 inset-lg-left-0 inset-lg-right-0'>
                        <blockquote className='quote-strict quote-strict-inverse'>
                          <p>
                            <q>
                              TaxExpert’s expertise and knowledge of USA and
                              Indian Tax law assisted me in answering tricky tax
                              questions and being able to better understand the
                              tax implications of various financial transactions
                              internationally. Very professional team!
                            </q>
                          </p>
                          <cite>Mark Wilson</cite>
                        </blockquote>
                      </div>
                      <div className='item inset-left-10 inset-right-10 inset-lg-left-0 inset-lg-right-0'>
                        <blockquote className='quote-strict quote-strict-inverse'>
                          <p>
                            <q>
                              As part of brief interaction, have noticed a very
                              high professionalism on TaxExpert’s part! They are
                              very prompt and have managed to keep their word as
                              part of process. Will be glad to work with them in
                              future as well. Thank you very much!
                            </q>
                          </p>
                          <cite>Gregory Smith</cite>
                        </blockquote>
                      </div>
                      <div className='item inset-left-10 inset-right-10 inset-lg-left-0 inset-lg-right-0'>
                        <blockquote className='quote-strict quote-strict-inverse'>
                          <p>
                            <q>
                              Highly experienced team of tax advisors! Tom was a
                              tremendous asset to me in sorting out my tax
                              affairs. His excellent advice helped me to make a
                              complicated situation straightforward. I would
                              recommend him to anyone.
                            </q>
                          </p>
                          <cite>Ann Williams</cite>
                        </blockquote>
                      </div>
                      <div className='item inset-left-10 inset-right-10 inset-lg-left-0 inset-lg-right-0'>
                        <blockquote className='quote-strict quote-strict-inverse'>
                          <p>
                            <q>
                              It has been a pleasure to work with Starbis team
                              of professional designers. The Reactive team was
                              quick studies who learned about our complex
                              business and market and created a web presence
                              that we are all proud to call our own. Many thanks
                              to all of you!
                            </q>
                          </p>
                          <cite>Jane Miller</cite>
                        </blockquote>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className='section-50 section-sm-90 section-sm-bottom-100'>
            <div className='shell text-center'>
              <h3>Latest News</h3>
              <div className='range range-xs-center offset-top-45'>
                <div className='cell-xs-8 cell-sm-5 cell-lg-3'>
                  <div className='post-boxed reveal-lg-inline-block'>
                    <div className='post-boxed-img-wrap'>
                      <a href='blog-post.html'>
                        <img
                          src={
                            require('../../assets/images/home-1-268x182.jpg')
                              .default
                          }
                          alt=''
                          width='268'
                          height='182'
                        />
                      </a>
                    </div>
                    <div className='post-boxed-caption'>
                      <div className='post-boxed-title text-bold'>
                        <a href='blog-post.html'>
                          The Top 5 Tax Paper Downloads
                        </a>
                      </div>
                      <div className='offset-top-5'>
                        <ul className='list-inline list-inline-dashed text-uppercase font-accent'>
                          <li>JUNE 14, 2016</li>
                          <li>
                            <span>
                              by
                              <a href='#' className='text-primary'>
                                Sam Kromstain
                              </a>
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='cell-xs-8 cell-sm-5 cell-lg-3 offset-top-40 offset-sm-top-0'>
                  <div className='post-boxed reveal-lg-inline-block'>
                    <div className='post-boxed-img-wrap'>
                      <a href='blog-post.html'>
                        <img
                          src={
                            require('../../assets/images/home-2-268x182.jpg')
                              .default
                          }
                          alt=''
                          width='268'
                          height='182'
                        />
                      </a>
                    </div>
                    <div className='post-boxed-caption'>
                      <div className='post-boxed-title text-bold'>
                        <a href='blog-post.html'>
                          Exploring American Taxes: Then & Now
                        </a>
                      </div>
                      <div className='offset-top-5'>
                        <ul className='list-inline list-inline-dashed text-uppercase font-accent'>
                          <li>JUNE 20, 2016</li>
                          <li>
                            <span>
                              by
                              <a href='#' className='text-primary'>
                                Vanessa Ives
                              </a>
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='cell-xs-8 cell-sm-5 cell-lg-3 offset-top-40 offset-lg-top-0'>
                  <div className='post-boxed reveal-lg-inline-block'>
                    <div className='post-boxed-img-wrap'>
                      <a href='blog-post.html'>
                        <img
                          src={
                            require('../../assets/images/home-3-268x182.jpg')
                              .default
                          }
                          alt=''
                          width='268'
                          height='182'
                        />
                      </a>
                    </div>
                    <div className='post-boxed-caption'>
                      <div className='post-boxed-title text-bold'>
                        <a href='blog-post.html'>
                          The Tax Consequences Of Brexit
                        </a>
                      </div>
                      <div className='offset-top-5'>
                        <ul className='list-inline list-inline-dashed text-uppercase font-accent'>
                          <li>JUNE 23, 2016</li>
                          <li>
                            <span>
                              by
                              <a href='#' className='text-primary'>
                                Amanda Smith
                              </a>
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='cell-xs-8 cell-sm-5 cell-lg-3 offset-top-40 offset-lg-top-0'>
                  <div className='post-boxed reveal-lg-inline-block'>
                    <div className='post-boxed-img-wrap'>
                      <a href='blog-post.html'>
                        <img
                          src={
                            require('../../assets/images/home-4-268x182.jpg')
                              .default
                          }
                          alt=''
                          width='268'
                          height='182'
                        />
                      </a>
                    </div>
                    <div className='post-boxed-caption'>
                      <div className='post-boxed-title text-bold'>
                        <a href='blog-post.html'>
                          What You Need to File Your Taxes This Year
                        </a>
                      </div>
                      <div className='offset-top-5'>
                        <ul className='list-inline list-inline-dashed text-uppercase font-accent'>
                          <li>JUNE 12, 2016</li>
                          <li>
                            <span>
                              by
                              <a href='#' className='text-primary'>
                                David Nicholson
                              </a>
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <Footer />
        </div>
        <div id='form-output-global' className='snackbars'></div>
        <div tabIndex='-1' role='dialog' aria-hidden='true' className='pswp'>
          <div className='pswp__bg'></div>
          <div className='pswp__scroll-wrap'>
            <div className='pswp__container'>
              <div className='pswp__item'></div>
              <div className='pswp__item'></div>
              <div className='pswp__item'></div>
            </div>
            <div className='pswp__ui pswp__ui--hidden'>
              <div className='pswp__top-bar'>
                <div className='pswp__counter'></div>
                <button
                  title='Close (Esc)'
                  className='pswp__button pswp__button--close'
                ></button>
                <button
                  title='Share'
                  className='pswp__button pswp__button--share'
                ></button>
                <button
                  title='Toggle fullscreen'
                  className='pswp__button pswp__button--fs'
                ></button>
                <button
                  title='Zoom in/out'
                  className='pswp__button pswp__button--zoom'
                ></button>
                <div className='pswp__preloader'>
                  <div className='pswp__preloader__icn'>
                    <div className='pswp__preloader__cut'>
                      <div className='pswp__preloader__donut'></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='pswp__share-modal pswp__share-modal--hidden pswp__single-tap'>
                <div className='pswp__share-tooltip'></div>
              </div>
              <button
                title='Previous (arrow left)'
                className='pswp__button pswp__button--arrow--left'
              ></button>
              <button
                title='Next (arrow right)'
                className='pswp__button pswp__button--arrow--right'
              ></button>
              <div className='pswp__caption'>
                <div className='pswp__caption__cent'></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default IndexPage;
