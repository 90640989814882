import React, { Component } from 'react';
import Loader from '../components/loader';
import Header from '../components/header';
import Footer from '../components/footer';

class Careers extends Component {
  render() {
    return (
      <div className='page'>
        <Loader />
        <Header tag='index' />
        <section
          style={{
            backgroundImage: `url(${
              require('../../assets/images/bg-image-11.jpg').default
            })`,
          }}
          class='section-30 section-sm-40 section-md-66 section-lg-bottom-90 bg-gray-dark page-title-wrap'
        >
          <div class='shell'>
            <div class='page-title'>
              <h2>Careers</h2>
            </div>
          </div>
        </section>

        <section class='section-60 section-sm-90 bg-athens-gray'>
          <div class='shell'>
            <div class='range'>
              <div class='cell-xs-12'>
                <h3>Join Our Team</h3>
              </div>
            </div>
            <div class='range range-lg-justify offset-top-15'>
              <div class='cell-sm-10 cell-md-7 offset-lg-top-15'>
                <div class='inset-md-right-15 inset-lg-right-0'>
                  <p>
                    Take a job opportunity of a lifetime – join the team of
                    Starbis. We cherish active tech savvies willing to work on
                    projects of any type and complexity. If you are enthusiastic
                    about tech innovations and ready to make impactful
                    decisions, feel free to send us your CV. We are interested
                    in hiring professionals for a long term and also provide an
                    opportunity of remote work.
                  </p>
                </div>
              </div>
              <div class='cell-sm-6 cell-md-4 offset-top-40 offset-md-top-0'>
                <a href='/our-team' class='btn btn-primary btn-block'>
                  get started now
                </a>
              </div>
            </div>
          </div>
        </section>

        <section class='section-66 section-sm-90'>
          <div class='shell'>
            <div class='range'>
              <div class='cell-xs-12'>
                <h3>Explore Our Corporate Culture</h3>
                <p style={{ maxWidth: '600px' }}>
                  At Starbis, we provide career solutions to people interested
                  in conducting broad-scale research and making technological
                  breakthroughs based on them. If you are one of them, learn
                  more about the main points of our corporate culture below.
                </p>
              </div>
            </div>
            <div class='range'>
              <div class='cell-xs-12'>
                <ul class='list-blocks'>
                  <li
                    style={{
                      backgroundImage: `url(${
                        require('../../assets/images/careers-1-1171x519.jpg')
                          .default
                      })`,
                    }}
                    class='block-image-plate bg-gray-base bg-image'
                  >
                    <div class='block-inner'>
                      <div class='block-header'>
                        <h3>The Main Corporate Values of TaxExpert</h3>
                      </div>
                      <div class='block-body'>
                        <div class='block-left'>
                          <div class='block-list-counter'></div>
                        </div>
                        <div class='block-text'>
                          <p>
                            The corporate culture of Starbis is based on
                            openness, respect and equality regardless of the
                            religion, sex, race, age, etc. These core values
                            help us build a friendly working environment, where
                            all the employees can feel comfortable and
                            significant. We believe that the more comfort you
                            get, the better results you’ll produce.
                          </p>
                          <p>
                            Members of our staff are free to choose working
                            hours themselves, without any limitations. Such a
                            strategy allows us to make the most of each
                            employee’s potential, as they work when they are
                            maximally productive.
                          </p>
                          <p>
                            What you may also find attractive about our
                            corporation is a competitive benefits package. It
                            includes a paid vacation, sick leave and national
                            holidays, medical insurance, gym and swimming pool,
                            parking and mobile phone reimbursement. Our
                            corporate fridge is also full of free snacks and
                            drinks.
                          </p>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li
                    style={{
                      backgroundImage: `url(${
                        require('../../assets/images/careers-2-1171x519.jpg')
                          .default
                      })`,
                    }}
                    class='block-image-plate bg-gray-base bg-image'
                  >
                    <div class='block-inner'>
                      <div class='block-header'>
                        <h3>Diversity of Learning Opportunities at Starbis</h3>
                      </div>
                      <div class='block-body'>
                        <div class='block-left'>
                          <div class='block-list-counter'></div>
                        </div>
                        <div class='block-text'>
                          <p>
                            When you join our team, you’ll dive into a
                            knowledge-sharing environment. We encourage our
                            employees to attend multiple conferences and
                            workshops held both inside the company and on the
                            outside. Keep in mind that you can take part in
                            conferences as both a listener and speaker.
                          </p>
                          <p>
                            As a part of our benefits package, we also offer
                            multiple courses for you to master new subjects. Our
                            working environment is full of learning
                            opportunities, which will motivate you to reach new
                            professional heights.
                          </p>
                          <p>
                            Offices of Starbis are located in different corners
                            of the globe, so be ready to travel around the
                            world. To save your budget, we cover all the travel
                            costs including transportation, accommodation, food,
                            and even some leisure activities. Our company gives
                            you a perfect chance to explore the world and
                            immerse in different cultures.
                          </p>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li
                    style={{
                      backgroundImage: `url(${
                        require('../../assets/images/careers-3-1171x519.jpg')
                          .default
                      })`,
                    }}
                    class='block-image-plate bg-gray-base bg-image'
                  >
                    <div class='block-inner'>
                      <div class='block-header'>
                        <h3>Top Qualities We Are Looking For</h3>
                      </div>
                      <div class='block-body'>
                        <div class='block-left'>
                          <div class='block-list-counter'></div>
                        </div>
                        <div class='block-text'>
                          <p>
                            Searching for candidates, our HR managers prioritize
                            a number of qualities. To get a job at Starbis, you
                            must be a self-motivated person who isn’t afraid of
                            challenges and is ready to work overtime. As we work
                            with multiple innovative technologies, you must be a
                            quick learner. Attention to detail is also important
                            for us, as it is a solid prerequisite for fewer
                            bugs.
                          </p>
                          <p>
                            Our company consists of many teams, that’s why you
                            must be a team player and excellent communicator
                            both in writing and oral speech. Our work is also
                            based on mutual assistance, so be ready to lend a
                            helping hand whenever you’re asked.
                          </p>
                          <p>
                            You are unlikely to find a project manager who
                            wouldn’t appreciate punctuality and discipline. The
                            same goes for PMs of Starbis. If you want to build a
                            long-term career at our company, you will have to
                            avoid late arrivals, unnecessary breaks and hanging
                            out in social networks. Also be polite not only with
                            your boss, but also with colleagues and newcomers.
                          </p>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li
                    style={{
                      backgroundImage: `url(${
                        require('../../assets/images/careers-4-1171x519.jpg')
                          .default
                      })`,
                    }}
                    class='block-image-plate bg-gray-base bg-image'
                  >
                    <div class='block-inner'>
                      <div class='block-header'>
                        <h3>Check Interview Stages at Starbis</h3>
                      </div>
                      <div class='block-body'>
                        <div class='block-left'>
                          <div class='block-list-counter'></div>
                        </div>
                        <div class='block-text'>
                          <p>
                            Whenever you decide to send us your CV, be ready for
                            a job interview that goes in several stages. At the
                            first stage, we’d like to learn about your
                            experience, interests, and expectations. At the end
                            of the interview, you will need to undergo a test
                            allowing us to evaluate your skills.
                          </p>
                          <p>
                            After successful completion of the test, you’ll be
                            invited to the second interview stage. We will
                            arrange a meeting with your future PM to give you a
                            detailed overview of your project and key
                            responsibilities.
                          </p>
                          <p>
                            Next, our team will make a final decision on your
                            joining us. At the last stage of the interview, we
                            will discuss your salary, benefits package, and some
                            legal issues. If we reach an agreement, our HR will
                            take you on an excursion through our office
                            premises.
                          </p>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section class='section-66 section-sm-90 bg-athens-gray'>
          <div class='shell'>
            <div class='range'>
              <div class='cell-xs-12'>
                <h3>Your Career Starts Here</h3>
                <p class='text-big'>
                  Can't find the job you want? Send your resume to{' '}
                  <a href='mailto:#' class='text-primary'>
                    info@demolink.com
                  </a>
                </p>
              </div>
            </div>
            <div class='range offset-top-45'>
              <div class='cell-sm-6'>
                <a href='#' class='block-vacation'>
                  <h5>Insolvency Practitioner </h5>
                  <div class='block-meta'>
                    <ul class='list-objects-inline'>
                      <li>
                        <span class='icon icon-xs-smaller icon-primary material-icons-location_on'></span>
                        <span>London</span>
                      </li>
                      <li>
                        <span class='icon icon-xs-smaller icon-primary material-icons-av_timer'></span>
                        <span>Full Time</span>
                      </li>
                      <li>
                        <span class='icon icon-xs-smaller icon-primary mdi mdi-calendar'></span>
                        <time datetime='2016-01-01'>6 hours ago</time>
                      </li>
                    </ul>
                  </div>
                </a>
              </div>
              <div class='cell-sm-6 offset-top-30 offset-sm-top-0'>
                <a href='#' class='block-vacation'>
                  <h5>Tax Advisor</h5>
                  <div class='block-meta'>
                    <ul class='list-objects-inline'>
                      <li>
                        <span class='icon icon-xs-smaller icon-primary material-icons-location_on'></span>
                        <span>Athlanta</span>
                      </li>
                      <li>
                        <span class='icon icon-xs-smaller icon-primary material-icons-av_timer'></span>
                        <span>Freelance</span>
                      </li>
                      <li>
                        <span class='icon icon-xs-smaller icon-primary mdi mdi-calendar'></span>
                        <time datetime='2016-01-01'>3 day ago</time>
                      </li>
                    </ul>
                  </div>
                </a>
              </div>
              <div class='cell-sm-6 offset-top-30'>
                <a href='#' class='block-vacation'>
                  <h5>Forensic Accounting</h5>
                  <div class='block-meta'>
                    <ul class='list-objects-inline'>
                      <li>
                        <span class='icon icon-xs-smaller icon-primary material-icons-location_on'></span>
                        <span>Oakland</span>
                      </li>
                      <li>
                        <span class='icon icon-xs-smaller icon-primary material-icons-av_timer'></span>
                        <span>Full Time</span>
                      </li>
                      <li>
                        <span class='icon icon-xs-smaller icon-primary mdi mdi-calendar'></span>
                        <time datetime='2016-01-01'>4 days ago</time>
                      </li>
                    </ul>
                  </div>
                </a>
              </div>
              <div class='cell-sm-6 offset-top-30'>
                <a href='#' class='block-vacation'>
                  <h5>Office Manager</h5>
                  <div class='block-meta'>
                    <ul class='list-objects-inline'>
                      <li>
                        <span class='icon icon-xs-smaller icon-primary material-icons-location_on'></span>
                        <span>New York</span>
                      </li>
                      <li>
                        <span class='icon icon-xs-smaller icon-primary material-icons-av_timer'></span>
                        <span>Full Time</span>
                      </li>
                      <li>
                        <span class='icon icon-xs-smaller icon-primary mdi mdi-calendar'></span>
                        <time datetime='2016-01-01'>week ago</time>
                      </li>
                    </ul>
                  </div>
                </a>
              </div>
              <div class='cell-sm-6 offset-top-30'>
                <a href='#' class='block-vacation'>
                  <h5>Tax Consultant</h5>
                  <div class='block-meta'>
                    <ul class='list-objects-inline'>
                      <li>
                        <span class='icon icon-xs-smaller icon-primary material-icons-location_on'></span>
                        <span>Bristol</span>
                      </li>
                      <li>
                        <span class='icon icon-xs-smaller icon-primary material-icons-av_timer'></span>
                        <span>Freelance</span>
                      </li>
                      <li>
                        <span class='icon icon-xs-smaller icon-primary mdi mdi-calendar'></span>
                        <time datetime='2016-01-01'>month ago</time>
                      </li>
                    </ul>
                  </div>
                </a>
              </div>
              <div class='cell-sm-6 offset-top-30'>
                <a href='#' class='block-vacation'>
                  <h5>Senior Tax Consultant</h5>
                  <div class='block-meta'>
                    <ul class='list-objects-inline'>
                      <li>
                        <span class='icon icon-xs-smaller icon-primary material-icons-location_on'></span>
                        <span>Tampa</span>
                      </li>
                      <li>
                        <span class='icon icon-xs-smaller icon-primary material-icons-av_timer'></span>
                        <span>Full Time</span>
                      </li>
                      <li>
                        <span class='icon icon-xs-smaller icon-primary mdi mdi-calendar'></span>
                        <time datetime='2016-01-01'>May 12, 2016</time>
                      </li>
                    </ul>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}

export default Careers;
