import React, { Component } from 'react';

import { client } from '../client';

class BlogPosts extends Component {
  getBlogPosts = async () => {
    let response = await client.getEntries();
    console.log(response.items[0]);
    this.setState({
      articles: response.items[0],
    });
  };
}

export default BlogPosts;
