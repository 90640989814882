import React, { Component } from 'react';
import Loader from '../components/loader';
import Header from '../components/header';
import Footer from '../components/footer';
import Article from '../components/article';

import BlogController from '../../controllers/blogs';

import { client } from '../../client';

class Blogs extends BlogController {
  constructor() {
    super();
    this.state = {
      articles: [],
    };
  }
  componentDidMount = () => {
    // this.getBlogPosts();
    // console.log(this.state.articles);
    client.getEntries().then((response) => {
      console.log(response);
      this.setState({
        articles: response.items.reverse(),
      });
    });
  };
  render() {
    return (
      <div className='page'>
        <Loader />
        <Header tab='blogs' />
        <section
          style={{
            backgroundImage: `url(${
              require('../../assets/images/bg-image-3.jpg').default
            })`,
          }}
          class='section-30 section-sm-40 section-md-66 section-lg-bottom-90 bg-gray-dark page-title-wrap'
        >
          <div class='shell'>
            <div class='page-title'>
              <h2>Blog</h2>
            </div>
          </div>
        </section>
        <section class='section-50 section-sm-75 section-lg-100 bg-athens-lighten'>
          <div class='shell'>
            <div class='range range-sm-center range-md-left'>
              <div class='cell-sm-9 cell-md-6'>
                {this.state.articles.map((article, key) => {
                  if (key % 2 === 0)
                    return (
                      <Article
                        index={key}
                        url={article.fields.url}
                        title={article.fields.title}
                        imageUrl={
                          article.fields.image
                            ? article.fields.image.fields.file.url
                            : ''
                        }
                        content={article.fields.content}
                        createdAt={article.sys.createdAt}
                      />
                    );
                })}
              </div>
              <div class='cell-sm-9 cell-md-6 offset-top-30 offset-md-top-0'>
                {this.state.articles.map((article, key) => {
                  if (key % 2 !== 0)
                    return (
                      <Article
                        index={key}
                        url={article.fields.url}
                        title={article.fields.title}
                        imageUrl={
                          article.fields.image
                            ? article.fields.image.fields.file.url
                            : ''
                        }
                        content={article.fields.content}
                        createdAt={article.sys.createdAt}
                      />
                    );
                })}
              </div>
            </div>
            <div class='range text-center offset-top-40 offset-sm-top-60 offset-lg-top-100'>
              <div class='cell-xs-12'>
                <ul class='pagination-custom'>
                  <li>
                    <a href='#'>Prev</a>
                  </li>
                  <li>
                    <a href='#'>1</a>
                  </li>
                  <li>
                    <a href='#'>2</a>
                  </li>
                  <li class='active'>
                    <a href='#'>3</a>
                  </li>
                  <li>
                    <a href='#'>4</a>
                  </li>
                  <li>
                    <a href='#'>Next</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}

export default Blogs;
