import React, { Component } from 'react';
const moment = require('moment');

class Article extends Component {
  render() {
    return (
      <article class='post-modern'>
        <div class='post-header'>
          <h5>
            <a href={this.props.url}> {this.props.title}</a>
          </h5>
        </div>
        <div class='post-body'>
          {this.props.imageUrl !== '' && (
            <figure>
              <img src={this.props.imageUrl} alt='' width='570' height='321' />
            </figure>
          )}

          <div class='post-inset'>
            <p>{this.props.content}</p>
          </div>
        </div>
        <div class='post-footer'>
          <div class='post-meta'>
            <ul class='post-list'>
              <li class='object-inline'>
                <span class='icon icon-xxs icon-gray-base material-icons-query_builder'></span>
                <time datetime='2016-01-01'>
                  {moment(this.props.createdAt).format('DD-MM-YYYY HH:MM')}
                </time>
              </li>
              {/* <li class='object-inline'>
                <span class='icon icon-xxs icon-gray-base material-icons-loyalty'></span>
                <ul class='list-tags-inline'>
                  <li>
                    <a href='#'>Business</a>
                  </li>
                  <li>
                    <a href='#'>Marketing</a>
                  </li>
                </ul>
              </li> */}
            </ul>
          </div>
          {/* <div class='post-type'>
            <span class='icon icon-xxs icon-gray-base material-icons-videocam'></span>
          </div> */}
        </div>
      </article>
    );
  }
}

export default Article;
