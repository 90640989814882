import React, { Component } from 'react';
import ReactWhatsapp from 'react-whatsapp';

class Footer extends Component {
  render() {
    return (
      <React.Fragment>
        <section class='section-40 section-sm-60 bg-cello'>
          <div class='shell'>
            <div class='range range-xs-center'>
              <div class='cell-xs-10 cell-sm-11 cell-md-12'>
                <div class='range'>
                  <div class='cell-sm-6 cell-md-4'>
                    <div class='inset-md-right-30'>
                      <h6 class='text-uppercase text-big'>About us</h6>
                      <p class='offset-top-27 text-bismark'>
                        Our firm has been providing expert tax and consulting
                        services to individuals and small businesses throughout
                        the country since 2010.
                      </p>
                    </div>
                    <div class='offset-top-22'>
                      <div class='group-md group-top'>
                        <div>
                          <div class='unit unit-middle unit-horizontal unit-spacing-xs'>
                            <div class='unit-left'>
                              <span class='icon icon-xs-smaller icon-primary fa-phone'></span>
                            </div>
                            <div class='unit-body'>
                              <a
                                href='callto:#'
                                class='link link-bold link-white-v2'
                              >
                                (+61) 433 470 594
                              </a>
                            </div>
                          </div>
                          <div class='inset-left-30'>
                            <a
                              href='mailto:#'
                              class='link-white-v2 text-italic'
                            >
                              taxbysam@gmail.com
                            </a>
                          </div>
                        </div>
                        <div>
                          <ul class='list-inline list-inline-reset'>
                            <li>
                              <a
                                href='https://www.facebook.com/Taxbysam-1605477543041650'
                                className='icon icon-round icon-san-juan-filled icon-xxs-smallest fa fa-facebook'
                              ></a>
                            </li>
                            <li>
                              <ReactWhatsapp number='+61-433470594' message=''>
                                <a className='icon icon-round icon-san-juan-filled icon-xxs-smallest fa fa-whatsapp' />
                              </ReactWhatsapp>
                            </li>
                            <li>
                              <a
                                href='https://www.google.com/maps/place/Tax+Today+Gold+Coast/@-27.9649783,153.4099838,17z/data=!3m1!4b1!4m5!3m4!1s0x6b910fed104153c5:0x19c4160df094901a!8m2!3d-27.9649835!4d153.4121625?authuser=2'
                                className='icon icon-round icon-san-juan-filled icon-xxs-smallest fa fa-google-plus'
                              ></a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class='cell-sm-6 cell-md-4 offset-top-50 offset-sm-top-0'>
                    <h6 class='text-uppercase text-big'>Quick links</h6>
                    <div
                      style={{ maxWidth: '340px' }}
                      class='row offset-top-22 offset-md-top-30'
                    >
                      <div class='col-xs-6'>
                        <ul class='list-marked-variant-2'>
                          <li>
                            <a href='/'>Home</a>
                          </li>
                          <li>
                            <a href='/services'>Services</a>
                          </li>
                          <li>
                            <a href='/careers'>Careers</a>
                          </li>
                          <li>
                            <a href='/blogs'>Blog</a>
                          </li>
                        </ul>
                      </div>
                      <div class='col-xs-6'>
                        <ul class='list-marked-variant-2'>
                          <li>
                            <a href='/about-us'>About us</a>
                          </li>
                          <li>
                            <a href='/contact-us'>Contacts</a>
                          </li>
                          <li>
                            <a href='/appointment'>Appointment</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  {/* <div class='cell-sm-6 cell-md-4 offset-top-50 offset-md-top-0'>
                    <h6 class='text-uppercase text-big'>Subscribe</h6>
                    <div class='offset-top-22 offset-md-top-30'>
                      <p class='text-bismark'>
                        Get the latest updates and offers.
                      </p>
                      <form
                        data-form-output='form-output-global'
                        data-form-type='subscribe'
                        method='post'
                        action='bat/rd-mailform.php'
                        class='rd-mailform form-classic form-inline offset-top-15'
                      >
                        <div class='form-group'>
                          <input
                            id='contact-email'
                            type='email'
                            name='email'
                            data-constraints='@Email @Required'
                            class='form-control'
                          />
                          <label for='contact-email' class='form-label'>
                            Enter Your E-mail
                          </label>
                        </div>
                        <button
                          type='submit'
                          aria-label='Subscribe'
                          class='btn btn-icon-only btn-primary'
                        >
                          <span class='icon icon-xs icon-white fa-envelope-o'></span>
                        </button>
                      </form>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </section>
        <footer className='page-foot section-15 bg-cloud-burst'>
          <div className='shell text-center'>
            <div className='range'>
              <div className='cell-sm-12'>
                <p className='rights text-bismark'>
                  <span>&#169;&nbsp;</span>
                  <span id='copyright-year'></span>
                  <span>TaxExpert.&nbsp;</span>
                  <a href='privacy-policy.html' className='text-bismark'>
                    Privacy Policy
                  </a>
                </p>
              </div>
            </div>
          </div>
        </footer>
      </React.Fragment>
    );
  }
}

export default Footer;
