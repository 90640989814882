import React, { Component } from 'react';

class PageNotFound extends Component {
  render() {
    return (
      <body
        style={{
          backgroundImage: `url(${
            require('../../assets/images/bg-image-9.jpg').default
          })`,
        }}
        class='one-screen-page bg-gray-darker bg-image'
      >
        <div class='page'>
          <div class='page-loader page-loader-variant-1'>
            <div>
              <a href='index.html' class='brand brand-md brand-inverse'>
                <img
                  src={
                    require('../../assets/images/logo-light-150x27.png').default
                  }
                  alt=''
                  width='150'
                  height='27'
                />
              </a>
              <div class='page-loader-body'>
                <div id='spinningSquaresG'>
                  <div id='spinningSquaresG_1' class='spinningSquaresG'></div>
                  <div id='spinningSquaresG_2' class='spinningSquaresG'></div>
                  <div id='spinningSquaresG_3' class='spinningSquaresG'></div>
                  <div id='spinningSquaresG_4' class='spinningSquaresG'></div>
                  <div id='spinningSquaresG_5' class='spinningSquaresG'></div>
                  <div id='spinningSquaresG_6' class='spinningSquaresG'></div>
                  <div id='spinningSquaresG_7' class='spinningSquaresG'></div>
                  <div id='spinningSquaresG_8' class='spinningSquaresG'></div>
                </div>
              </div>
            </div>
          </div>
          <div class='page-inner'>
            <header class='page-head'>
              <div class='page-head-inner'>
                <div class='shell text-center'>
                  <a href='index.html' class='brand brand-md brand-inverse'>
                    <img
                      src={
                        require('../../assets/images/logo-light-150x27.png')
                          .default
                      }
                      alt=''
                      width='150'
                      height='27'
                    />
                  </a>
                </div>
              </div>
            </header>
            <section class='text-center'>
              <div class='shell'>
                <div class='range range-sm-center'>
                  <div class='cell-sm-9 cell-md-8'>
                    <h5>Sorry, but page was not found</h5>
                    <div class='text-extra-large-bordered offset-top-15'>
                      <p>404</p>
                    </div>
                    <p class='text-white'>
                      You may have mistyped the address or the page may have
                      moved.
                    </p>
                    <div class='group-xl offset-top-40 offset-sm-top-60 offset-xl-top-120'>
                      <a href='/' class='btn btn-primary'>
                        Back to home
                      </a>
                      <a href='contact-us.html' class='btn btn-white-outline'>
                        contact us
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section class='page-foot'>
              <div class='page-foot-inner'>
                <div class='shell text-center'>
                  <div class='range'>
                    <div class='cell-xs-12'>
                      <p class='rights text-small text-light' />
                      <span>TaxExpert</span>
                      <span>&nbsp;&#169;&nbsp;</span>
                      <span id='copyright-year'></span>
                      <span>All Rights Reserved</span>
                      <br class='veil-sm' />
                      <a href='#' class='link-primary-inverse'>
                        Terms of Use
                      </a>
                      <span>and</span>
                      <a
                        href='privacy-policy.html'
                        class='link-primary-inverse'
                      >
                        Privacy Policy
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
        <div id='form-output-global' class='snackbars'></div>
        <div tabindex='-1' role='dialog' aria-hidden='true' class='pswp'>
          <div class='pswp__bg'></div>
          <div class='pswp__scroll-wrap'>
            <div class='pswp__container'>
              <div class='pswp__item'></div>
              <div class='pswp__item'></div>
              <div class='pswp__item'></div>
            </div>
            <div class='pswp__ui pswp__ui--hidden'>
              <div class='pswp__top-bar'>
                <div class='pswp__counter'></div>
                <button
                  title='Close (Esc)'
                  class='pswp__button pswp__button--close'
                ></button>
                <button
                  title='Share'
                  class='pswp__button pswp__button--share'
                ></button>
                <button
                  title='Toggle fullscreen'
                  class='pswp__button pswp__button--fs'
                ></button>
                <button
                  title='Zoom in/out'
                  class='pswp__button pswp__button--zoom'
                ></button>
                <div class='pswp__preloader'>
                  <div class='pswp__preloader__icn'>
                    <div class='pswp__preloader__cut'>
                      <div class='pswp__preloader__donut'></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class='pswp__share-modal pswp__share-modal--hidden pswp__single-tap'>
                <div class='pswp__share-tooltip'></div>
              </div>
              <button
                title='Previous (arrow left)'
                class='pswp__button pswp__button--arrow--left'
              ></button>
              <button
                title='Next (arrow right)'
                class='pswp__button pswp__button--arrow--right'
              ></button>
              <div class='pswp__caption'>
                <div class='pswp__caption__cent'></div>
              </div>
            </div>
          </div>
        </div>
      </body>
    );
  }
}

export default PageNotFound;
