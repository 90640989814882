import React, { Component } from 'react';
import Loader from '../components/loader';
import Header from '../components/header';
import Footer from '../components/footer';
import service from '../../services/index';
import Modal from 'react-modal';
import moment from 'moment';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';

class MakeAnAppointment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      // date: '',
      // time: '',
      phoneNumber: '',
      message: '',
      disabled: false,
      showModal: false,
    };
    this.submitAppointmentForm = this.submitAppointmentForm.bind(this);
    // this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  handleNameChange = (event) => {
    this.setState({ name: event.target.value });
  };
  handleEmailChange = (event) => {
    this.setState({ email: event.target.value });
  };
  handleDateChange = (event) => {
    this.setState({ date: event.format('DD-MM-YYYY') });
  };
  handleTimeChange = (event) => {
    this.setState({ time: event.format('hh:mm A') });
  };
  handlePhoneNumberChange = (event) => {
    this.setState({ phoneNumber: event.target.value });
  };
  handleMessageChange = (event) => {
    this.setState({ message: event.target.value });
  };

  // handleOpenModal() {
  //   this.setState({ showModal: true });
  // }

  handleCloseModal() {
    this.setState({ showModal: false });
  }

  submitAppointmentForm = async () => {
    if (this.state.disabled) {
      return;
    }
    // this.handleOpenModal();
    this.setState({ disabled: true, showModal: true });
    const payload = {
      name: this.state.name,
      email: this.state.email,
      // date: this.state.date,
      // time: this.state.time,
      phoneNumber: this.state.phoneNumber,
      message: this.state.message,
    };
    let response = await service.fetchPost('/notify-sam', payload);
    if (response[0] === true) {
      this.setState({
        name: '',
        email: '',
        // date: '',
        // time: '',
        phoneNumber: '',
        message: '',
        disabled: false,
        showModal: false,
      });
      // alert('Thank You!');
    } else {
      this.setState({
        name: '',
        email: '',
        // date: '',
        // time: '',
        phoneNumber: '',
        message: '',
        disabled: false,
        showModal: false,
      });
      // alert(response[1].message);
    }
  };

  renderAppointmentForm = () => {
    return (
      <div>
        <div class='range range-7'>
          <div class='cell-sm-3'>
            <div class='form-group'>
              <input
                id='appointment-name'
                type='text'
                name='name'
                value={this.state.name}
                //data-constraints='@Required'
                class='form-control'
                onChange={(e) => this.handleNameChange(e)}
              />
              {this.state.name === '' ? (
                <label for='appointment-name' class='form-label'>
                  Name
                </label>
              ) : (
                ''
              )}
            </div>
          </div>
          <div class='cell-sm-4 offset-top-40 offset-sm-top-0'>
            <div class='form-group'>
              <input
                id='appointment-email'
                type='email'
                name='email'
                value={this.state.email}
                // data-constraints='@Email @Required'
                class='form-control'
                onChange={(e) => this.handleEmailChange(e)}
              />
              {this.state.email === '' ? (
                <label for='appointment-email' class='form-label'>
                  Email
                </label>
              ) : (
                ''
              )}
            </div>
          </div>
          {/* <div class='cell-sm-3 offset-top-40'>
            <div class='form-group'>
              <input
								id="appointment-date"
								type="text"
								name="date"
								//value={this.state.date}
								data-constraints="@Required"
								data-time-picker="date"
								class="form-control"
							/>
              <Datetime onChange={this.handleDateChange} timeFormat={false} />
              {this.state.date === '' ? (
                <label for='appointment-date' class='form-label'>
                  Date
                </label>
              ) : (
                ''
              )}
            </div>
          </div> */}
          {/* <div class='cell-sm-4 offset-top-40'>
            <div class='form-group'>
              <input
								id="appointment-time"
								type="text"
								name="time"
								value={this.state.time}
								data-constraints="@Required"
								data-time-picker="time"
								class="form-control"
								onChange={(e) => this.handleTimeChange(e)}
							/>

              <Datetime onChange={this.handleTimeChange} dateFormat={false} />
              {this.state.time === '' ? (
                <label for='appointment-date' class='form-label'>
                  Time Interval
                </label>
              ) : (
                ''
              )}
            </div>
          </div> */}
          <div class='cell-sm-3 offset-top-40'>
            <div class='form-group'>
              <input
                id='appointment-phoneNumber'
                type='text'
                name='phoneNumber'
                value={this.state.phoneNumber}
                data-constraints='@Required'
                class='form-control'
                onChange={(e) => this.handlePhoneNumberChange(e)}
              />
              {this.state.phoneNumber === '' ? (
                <label for='appointment-phoneNumber' class='form-label'>
                  Phone Number
                </label>
              ) : (
                ''
              )}
            </div>
          </div>
          <div class='cell-xs-7 offset-top-40'>
            <div class='form-group'>
              <div class='textarea-lined-wrap'>
                <textarea
                  id='appointment-message'
                  name='message'
                  value={this.state.message}
                  // data-constraints='@Required'
                  class='form-control'
                  onChange={(e) => this.handleMessageChange(e)}
                ></textarea>
                {this.state.message === '' ? (
                  <label for='appointment-message' class='form-label'>
                    Message
                  </label>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
          <div class='cell-xs-5 offset-top-30 offset-xs-top-30 offset-sm-top-50'>
            <button
              type='submit'
              class='btn btn-primary btn-block'
              onClick={() => this.submitAppointmentForm()}
              disabled={this.state.disabled}
            >
              Send
            </button>
            <Modal
              isOpen={this.state.showModal}
              contentLabel='Minimal Modal Example'
              className='popmodal'
              overlayClassName='popoverlay'
              onRequestClose={this.handleCloseModal}
            >
              <div className='popuptext'>
                Thanks for being awesome! We have received your message and
                would like to thank you for writing to us. we will reply by
                email as soon as possible.Talk to you soon, TAXBYSAM
              </div>
              {/* <button onClick={this.handleCloseModal}>Close</button> */}
            </Modal>
          </div>
          <div class='cell-xs-2 offset-top-22 offset-xs-top-30 offset-sm-top-50'>
            <button type='reset' class='btn btn-silver-outline btn-block'>
              Reset
            </button>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className='page'>
        <Loader />
        <Header tab='index' />
        <section
          style={{
            backgroundImage: `url(${
              require('../../assets/images/bg-image-7.jpg').default
            })`,
          }}
          class='section-30 section-sm-40 section-md-66 section-lg-bottom-90 bg-gray-dark page-title-wrap'
        >
          <div class='shell'>
            <div class='page-title'>
              <h2>Appointment</h2>
            </div>
          </div>
        </section>

        <section class='section-60 section-sm-top-90 section-sm-bottom-100'>
          <div class='shell'>
            <div class='range'>
              <div class='cell-md-9 cell-lg-7'>
                <h3>Get In Touch</h3>
                {this.renderAppointmentForm()}
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}

export default MakeAnAppointment;
