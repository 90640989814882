const env = require('./config').api_server;

const Service = {
  fetchPost: async (url, body) => {
    console.log(url);
    console.log(body);
    let URL = env + url;

    const headers = { 'Content-Type': 'application/json' };
    try {
      const res = await fetch(URL, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(body),
      });
      const ress = await res.json();
      if (res.status >= 200 && res.status < 400) {
        return [true, ress];
      } else if (res.status >= 400) {
        return [res.status, ress];
      }
    } catch (e) {
      console.log(e);
      return false;
    }
  },
};

export default Service;
