import React, { Component } from 'react';
import Header from '../components/header';
import Footer from '../components/footer';
import Loader from '../components/loader';

class Aboutus extends Component {
  render() {
    return (
      <div className='page'>
        <Loader />
        <Header tab='aboutUs' />
        <section
          style={{
            backgroundImage: `url(${
              require('../../assets/images/bg-image-2.jpg').default
            })`,
          }}
          class='section-30 section-sm-40 section-md-66 section-lg-bottom-90 bg-gray-dark page-title-wrap'
        >
          <div class='shell'>
            <div class='page-title'>
              <h2>About Us</h2>
            </div>
          </div>
        </section>

        <section
          style={{
            backgroundImage: `url(${
              require('../../assets/images/about-1.jpg').default
            })`,
          }}
          class='section-60 section-sm-90 section-sm-bottom-100 bg-white bg-image bg-image-1'
        >
          <div class='shell'>
            <div class='range range-md-left'>
              <div class='cell-sm-8 cell-md-6 cell-lg-5'>
                <h3>What is Tax?</h3>
                <div class='offset-top-40'>
                  <p class='text-gray-darker'>
                    Tax is a dynamic and fast paced industry which requires not
                    only analytical ability, but excellent problem solving and
                    commercial skills. For everything that has economic
                    consequences, whether it is for a company, an individual or
                    the Government - tax is relevant, as is the need for tax
                    advisers.
                  </p>
                  <p class='text-gray-base'>
                    The complex and diverse nature of taxation means that tax
                    advisers rapidly develop areas of specialist knowledge.
                  </p>
                  {/* <a href='our-team.html' class='btn btn-primary'>
                    Read more
                  </a> */}
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          style={{
            backgroundImage: `url(${
              require('../../assets/images/about-2.jpg').default
            })`,
          }}
          class='section-66 section-sm-100 bg-gray-darker bg-image'
        >
          <div class='shell'>
            <div class='range range-xs-center range-sm-right'>
              <div class='cell-sm-8 cell-md-6 cell-lg-5'>
                <div class='box bg-cello'>
                  <h3 class='text-white'>Our Benefits</h3>
                  <div class='offset-top-40'>
                    <article class='icon-box-horizontal'>
                      <div class='unit unit-xs unit-xs-horizontal'>
                        <div class='unit-left'>
                          <span class='icon icon-primary icon-lg fl-puppets-book-bag offset-top-5'></span>
                        </div>
                        <div class='unit-body'>
                          <h5>
                            <a href='#'>Competency</a>
                          </h5>
                          <p class='text-white'>
                            We keep abreast of relevant trends and changes in
                            accounting treatment, tax law, and financial
                            management.
                          </p>
                        </div>
                      </div>
                    </article>
                  </div>
                  <div class='offset-top-30'>
                    <article class='icon-box-horizontal'>
                      <div class='unit unit-xs unit-xs-horizontal'>
                        <div class='unit-left'>
                          <span class='icon icon-primary icon-lg fl-puppets-dart19 offset-top-5'></span>
                        </div>
                        <div class='unit-body'>
                          <h5>
                            <a href='#'>Professionalism</a>
                          </h5>
                          <p class='text-white'>
                            We treat each client with dignity and respect. We
                            listen attentively to their goals and needs.
                          </p>
                        </div>
                      </div>
                    </article>
                  </div>
                  <div class='offset-top-30'>
                    <article class='icon-box-horizontal'>
                      <div class='unit unit-xs unit-xs-horizontal'>
                        <div class='unit-left'>
                          <span class='icon icon-primary icon-lg fl-puppets-loud1 offset-top-5'></span>
                        </div>
                        <div class='unit-body'>
                          <h5>
                            <a href='#'>Commitment</a>
                          </h5>
                          <p class='text-white'>
                            Our relationships with clients extend beyond
                            financial recordkeeping and reporting.
                          </p>
                        </div>
                      </div>
                    </article>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class='section-66 section-sm-90 section-lg-bottom-120'>
          <div class='shell'>
            <div class='range'>
              <div class='cell-xs-12'>
                <h3>Why Us?</h3>
              </div>
            </div>
            <div class='range range-lg-justify offset-top-40'>
              <div class='cell-sm-6 cell-lg-5 text-secondary'>
                <div class='inset-sm-right-15 inset-lg-right-0'>
                  <p class='text-gray-darker'>
                    Using a tax advisor to manage your taxation affairs can be
                    very rewarding. Professionals are much more likely to be
                    aware of more of the ways in which your taxes can be
                    optimised so that you pay the least possible amount.
                  </p>
                  <p class='text-gray-darker'>
                    The down side is, obviously, the cost involved in employing
                    such an advisor. This may seem a bit daunting but if you
                    ever find yourself out of your depth, don't worry -
                    TaxExpert's tax question and answer service is always on
                    hand.
                  </p>
                </div>
              </div>
              <div class='cell-sm-6 offset-top-40 offset-sm-top-0'>
                <div class='shift-sm-top-1'>
                  <ul class='list-progress'>
                    <li>
                      <p class='animated fadeIn text-gray-darker h7'>
                        Tax Consultancy Services
                      </p>
                      <div
                        data-value='70'
                        data-stroke='4'
                        data-easing='linear'
                        data-counter='true'
                        data-duration='1000'
                        data-trail='100'
                        class='progress-bar-js text-bold progress-bar-horizontal progress-bar-primary'
                      ></div>
                    </li>
                    <li>
                      <p class='animated fadeIn text-gray-darker h7'>
                        Tax Preperation
                      </p>
                      <div
                        data-value='54'
                        data-stroke='4'
                        data-easing='linear'
                        data-counter='true'
                        data-duration='1000'
                        data-trail='100'
                        class='progress-bar-js text-bold progress-bar-horizontal progress-bar-old-gold'
                      ></div>
                    </li>
                    <li>
                      <p class='animated fadeIn text-gray-darker h7'>
                        Bookkeeping
                      </p>
                      <div
                        data-value='87'
                        data-stroke='4'
                        data-easing='linear'
                        data-counter='true'
                        data-duration='1000'
                        data-trail='100'
                        class='progress-bar-js text-bold progress-bar-horizontal progress-bar-bermuda-gray'
                      ></div>
                    </li>
                    {/* <li>
                      <p class='animated fadeIn text-gray-darker h7'>
                        Bank Reconciliation
                      </p>
                      <div
                        data-value='90'
                        data-stroke='4'
                        data-easing='linear'
                        data-counter='true'
                        data-duration='1000'
                        data-trail='100'
                        class='progress-bar-js text-bold progress-bar-horizontal progress-bar-contessa'
                      ></div>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <section class='section-60 section-sm-100 bg-athens-gray'>
          <div class='shell'>
            <div class='range'>
              <div class='cell-xs-6 cell-sm-4 cell-md-3'>
                <div class='thumbnail-variant-2-wrap'>
                  <div class='thumbnail thumbnail-variant-2'>
                    <figure class='thumbnail-image'>
                      <img
                        src={
                          require('../../assets/images/team-9-246x300.jpg')
                            .default
                        }
                        alt=''
                        width='246'
                        height='300'
                      />
                    </figure>
                    <div class='thumbnail-inner'>
                      <div class='link-group'>
                        <span class='icon icon-xxs icon-primary material-icons-local_phone'></span>
                        <a href='callto:#' class='link-white'>
                          +1 (409) 987–5874
                        </a>
                      </div>
                      <div class='link-group'>
                        <span class='icon icon-xxs icon-primary fa-envelope-o'></span>
                        <a href='mailto:#' class='link-white'>
                          info@demolink.org
                        </a>
                      </div>
                    </div>
                    <div class='thumbnail-caption'>
                      <p class='text-header'>
                        <a href='team-member-profile.html'>Amanda Smith</a>
                      </p>
                      <div class='divider divider-md bg-white-02'></div>
                      <p class='text-caption'>Insolvency Practitioner</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class='cell-xs-6 cell-sm-4 cell-md-3 offset-top-40 offset-xs-top-0'>
                <div class='thumbnail-variant-2-wrap'>
                  <div class='thumbnail thumbnail-variant-2'>
                    <figure class='thumbnail-image'>
                      <img
                        src={
                          require('../../assets/images/team-10-246x300.jpg')
                            .default
                        }
                        alt=''
                        width='246'
                        height='300'
                      />
                    </figure>
                    <div class='thumbnail-inner'>
                      <div class='link-group'>
                        <span class='icon icon-xxs icon-primary material-icons-local_phone'></span>
                        <a href='callto:#' class='link-white'>
                          +1 (409) 987–5874
                        </a>
                      </div>
                      <div class='link-group'>
                        <span class='icon icon-xxs icon-primary fa-envelope-o'></span>
                        <a href='mailto:#' class='link-white'>
                          info@demolink.org
                        </a>
                      </div>
                    </div>
                    <div class='thumbnail-caption'>
                      <p class='text-header'>
                        <a href='team-member-profile.html'>David Nicholson</a>
                      </p>
                      <div class='divider divider-md bg-white-02'></div>
                      <p class='text-caption'>Tax Advisor</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class='cell-xs-6 cell-sm-4 cell-md-3 offset-top-40 offset-sm-top-0'>
                <div class='thumbnail-variant-2-wrap'>
                  <div class='thumbnail thumbnail-variant-2'>
                    <figure class='thumbnail-image'>
                      <img
                        src={
                          require('../../assets/images/team-11-246x300.jpg')
                            .default
                        }
                        alt=''
                        width='246'
                        height='300'
                      />
                    </figure>
                    <div class='thumbnail-inner'>
                      <div class='link-group'>
                        <span class='icon icon-xxs icon-primary material-icons-local_phone'></span>
                        <a href='callto:#' class='link-white'>
                          +1 (409) 987–5874
                        </a>
                      </div>
                      <div class='link-group'>
                        <span class='icon icon-xxs icon-primary fa-envelope-o'></span>
                        <a href='mailto:#' class='link-white'>
                          info@demolink.org
                        </a>
                      </div>
                    </div>
                    <div class='thumbnail-caption'>
                      <p class='text-header'>
                        <a href='team-member-profile.html'>Vanessa Ives</a>
                      </p>
                      <div class='divider divider-md bg-white-02'></div>
                      <p class='text-caption'>Forensic Accounting</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class='cell-xs-6 cell-sm-4 cell-md-3 offset-top-40 offset-md-top-0'>
                <div class='thumbnail-variant-2-wrap'>
                  <div class='thumbnail thumbnail-variant-2'>
                    <figure class='thumbnail-image'>
                      <img
                        src={
                          require('../../assets/images/team-12-246x300.jpg')
                            .default
                        }
                        alt=''
                        width='246'
                        height='300'
                      />
                    </figure>
                    <div class='thumbnail-inner'>
                      <div class='link-group'>
                        <span class='icon icon-xxs icon-primary material-icons-local_phone'></span>
                        <a href='callto:#' class='link-white'>
                          +1 (409) 987–5874
                        </a>
                      </div>
                      <div class='link-group'>
                        <span class='icon icon-xxs icon-primary fa-envelope-o'></span>
                        <a href='mailto:#' class='link-white'>
                          info@demolink.org
                        </a>
                      </div>
                    </div>
                    <div class='thumbnail-caption'>
                      <p class='text-header'>
                        <a href='team-member-profile.html'>Sam Kromstain</a>
                      </p>
                      <div class='divider divider-md bg-white-02'></div>
                      <p class='text-caption'>Office Manager</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        <section
          data-on='false'
          data-md-on='true'
          style={{
            backgroundImage: `url(${
              require('../../assets/images/clients-testimonials-parallax-3.jpg')
                .default
            })`,
          }}
          class='rd-parallax bg-gray-base bg-image'
        >
          <div
            data-speed='0.33'
            data-type='media'
            data-url='images/clients-testimonials-parallax-3.jpg'
            class='rd-parallax-layer'
          ></div>
          <div data-speed='0' data-type='html' class='rd-parallax-layer'>
            <div class='section-60 section-sm-90 bg-overlay-cello'>
              <div class='shell text-center'>
                <div class='range'>
                  <div class='cell-xs-12'>
                    <h3>What People Say</h3>
                  </div>
                </div>
                <div class='range offset-top-50 text-left'>
                  <div class='cell-xs-12'>
                    <div
                      data-items='1'
                      data-sm-items='2'
                      data-stage-padding='15'
                      data-loop='true'
                      data-margin='30'
                      data-mouse-drag='true'
                      data-nav='true'
                      data-dots='true'
                      data-dots-each='1'
                      class='owl-carousel owl-spacing-1 owl-nav-classic owl-style-minimal owl-carousel owl-spacing-1 owl-nav-classic owl-style-minimal'
                    >
                      <div class='item'>
                        <blockquote class='quote-bordered'>
                          <div class='quote-body'>
                            <div class='quote-open'>
                              <svg
                                version='1.1'
                                baseprofile='tiny'
                                xmlns='http://www.w3.org/2000/svg'
                                xmlnsXlink='http://www.w3.org/1999/xlink'
                                width='37px'
                                height='27px'
                                viewbox='0 0 21 15'
                                preserveAspectRatio='none'
                              >
                                <path d='M9.597,10.412c0,1.306-0.473,2.399-1.418,3.277c-0.944,0.876-2.06,1.316-3.349,1.316                    c-1.287,0-2.414-0.44-3.382-1.316C0.482,12.811,0,11.758,0,10.535c0-1.226,0.58-2.716,1.739-4.473L5.603,0H9.34L6.956,6.37                    C8.716,7.145,9.597,8.493,9.597,10.412z M20.987,10.412c0,1.306-0.473,2.399-1.418,3.277c-0.944,0.876-2.06,1.316-3.35,1.316                    c-1.288,0-2.415-0.44-3.381-1.316c-0.966-0.879-1.45-1.931-1.45-3.154c0-1.226,0.582-2.716,1.74-4.473L16.994,0h3.734l-2.382,6.37                    C20.106,7.145,20.987,8.493,20.987,10.412z'></path>
                              </svg>
                            </div>
                            <div class='quote-body-inner'>
                              <h6>Very professional team</h6>
                              <p class='text-white'>
                                <q>
                                  TaxExpert filed my tax returns for this year.
                                  They were very complicated as compared to any
                                  other due to a host of reasons. They handled
                                  my tax returns very professionally and
                                  accurately. I am pleased to recommend
                                  TaxExpert.{' '}
                                </q>
                              </p>
                            </div>
                          </div>
                          <div class='quote-footer'>
                            <div class='unit unit-horizontal unit-spacing-sm unit-middle'>
                              <div class='unit-left'>
                                <img
                                  src={
                                    require('../../assets/images/clients-testimonials-1-68x68.jpg')
                                      .default
                                  }
                                  alt=''
                                  width='68'
                                  height='68'
                                  class='img-circle'
                                />
                              </div>
                              <div class='unit-body'>
                                <cite>Mark Wilson</cite>
                                <p class='text-italic text-primary'>
                                  Top Manager
                                </p>
                              </div>
                            </div>
                          </div>
                        </blockquote>
                      </div>
                      <div class='item'>
                        <blockquote class='quote-bordered'>
                          <div class='quote-body'>
                            <div class='quote-open'>
                              <svg
                                version='1.1'
                                baseprofile='tiny'
                                xmlns='http://www.w3.org/2000/svg'
                                xmlnsXlink='http://www.w3.org/1999/xlink'
                                width='37px'
                                height='27px'
                                viewbox='0 0 21 15'
                                preserveAspectRatio='none'
                              >
                                <path d='M9.597,10.412c0,1.306-0.473,2.399-1.418,3.277c-0.944,0.876-2.06,1.316-3.349,1.316                    c-1.287,0-2.414-0.44-3.382-1.316C0.482,12.811,0,11.758,0,10.535c0-1.226,0.58-2.716,1.739-4.473L5.603,0H9.34L6.956,6.37                    C8.716,7.145,9.597,8.493,9.597,10.412z M20.987,10.412c0,1.306-0.473,2.399-1.418,3.277c-0.944,0.876-2.06,1.316-3.35,1.316                    c-1.288,0-2.415-0.44-3.381-1.316c-0.966-0.879-1.45-1.931-1.45-3.154c0-1.226,0.582-2.716,1.74-4.473L16.994,0h3.734l-2.382,6.37                    C20.106,7.145,20.987,8.493,20.987,10.412z'></path>
                              </svg>
                            </div>
                            <div class='quote-body-inner'>
                              <h6>Very knowledgeable tax accountants</h6>
                              <p class='text-white'>
                                <q>
                                  The services they offered me for this year's
                                  tax filing has been exemplary and of the
                                  highest class. I would recommend them for
                                  doing taxes. The service that they rendered
                                  was very timely and the fees for the service
                                  were reasonable.
                                </q>
                              </p>
                            </div>
                          </div>
                          <div class='quote-footer'>
                            <div class='unit unit-horizontal unit-spacing-sm unit-middle'>
                              <div class='unit-left'>
                                <img
                                  src={
                                    require('../../assets/images/clients-testimonials-2-68x68.jpg')
                                      .default
                                  }
                                  alt=''
                                  width='68'
                                  height='68'
                                  class='img-circle'
                                />
                              </div>
                              <div class='unit-body'>
                                <cite>Amanda Eberson</cite>
                                <p class='text-italic text-primary'>
                                  CEO, Eberson Co.
                                </p>
                              </div>
                            </div>
                          </div>
                        </blockquote>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}

export default Aboutus;
