import React, { Component } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

// components
import IndexPage from './views/features/index';
import AboutUs from './views/features/aboutUs';
import Services from './views/features/services';
import ContactUs from './views/features/contactUs';
import Blogs from './views/features/blogs';
import PageNotFound from './views/components/404';

// pages
import MakeAnAppointment from './views/pages/makeAnAppointment';
import Careers from './views/pages/careers';
import OurTeam from './views/pages/ourTeam';

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route path='/' exact component={() => <IndexPage />} />
          <Route path='/about-us' exact component={() => <AboutUs />} />
          <Route path='/services' exact component={() => <Services />} />
          <Route path='/blogs' exact component={() => <Blogs />} />
          <Route path='/contact-us' exact component={() => <ContactUs />} />
          <Route path='/careers' exact component={() => <Careers />} />
          <Route path='/our-team' exact component={() => <OurTeam />} />
          <Route
            path='/appointment'
            exact
            component={() => <MakeAnAppointment />}
          />
          <Route path='*' component={() => <PageNotFound />} />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
