import React, { Component } from 'react';
import Header from '../components/header';
import Loader from '../components/loader';
import Footer from '../components/footer';

class ContactUs extends Component {
  render() {
    return (
      <div className='page'>
        <Loader />
        <Header tab='contactUs' />
        <section
          style={{
            backgroundImage: `url(${
              require('../../assets/images/bg-image-7.jpg').default
            })`,
          }}
          class='section-30 section-sm-40 section-md-66 section-lg-bottom-90 bg-gray-dark page-title-wrap'
        >
          <div class='shell'>
            <div class='page-title'>
              <h2>Contact Us</h2>
            </div>
          </div>
        </section>

        <section class='section-60 section-sm-top-90 section-sm-bottom-100'>
          <div class='shell'>
            <div class='range range-md-justify'>
              <div class='cell-md-5 cell-lg-4'>
                <div class='inset-md-right-15 inset-lg-right-0'>
                  <div class='range'>
                    <div class='cell-sm-10 cell-md-12'>
                      <h3>How to Find Us</h3>
                      <p class='offset-sm-top-40 text-secondary'>
                        If you have any questions, just fill in the contact
                        form, and we will answer you shortly. If you are living
                        nearby, come visit TaxBySam at our office.
                      </p>
                    </div>
                    <div class='cell-sm-6 cell-md-12 offset-top-30 offset-sm-top-45'>
                      <h5>Office Address</h5>
                      <address class='contact-info'>
                        <p class='text-uppercase'>
                          2CP6+2V Southport, Queensland, Australia
                        </p>
                        <dl class='list-terms-inline'>
                          <dt>Telephone</dt>
                          <dd>
                            <a href='callto:#' class='link-secondary'>
                              +61 433 470 594
                            </a>
                          </dd>
                        </dl>
                        <dl class='list-terms-inline'>
                          <dt>E-mail</dt>
                          <dd>
                            <a href='mailto:#' class='link-primary'>
                              taxbysam@gmail.com
                            </a>
                          </dd>
                        </dl>
                      </address>
                    </div>
                    {/* <div class='cell-sm-6 cell-md-12 offset-top-30 offset-sm-top-45 offset-md-top-40'>
                      <h5>Support Centre</h5>
                      <address class='contact-info'>
                        <p class='text-uppercase'>
                          9870 ST VINCENT PLACE, GLASGOW, DC 45 FR 45
                        </p>
                        <dl class='list-terms-inline'>
                          <dt>Telephone</dt>
                          <dd>
                            <a href='callto:#' class='link-secondary'>
                              +1 800 603 6035
                            </a>
                          </dd>
                        </dl>
                        <dl class='list-terms-inline'>
                          <dt>E-mail</dt>
                          <dd>
                            <a href='mailto:#' class='link-primary'>
                              mail@demolink.org
                            </a>
                          </dd>
                        </dl>
                      </address>
                    </div> */}
                  </div>
                </div>
              </div>
              <div class='cell-md-7 cell-lg-6 offset-top-50 offset-md-top-0'>
                <h3>Get in Touch</h3>
                <form
                  data-form-output='form-output-global'
                  data-form-type='contact'
                  method='post'
                  action='bat/rd-mailform.php'
                  class='rd-mailform form-modern offset-top-30'
                >
                  <div class='range'>
                    <div class='cell-sm-6'>
                      <div class='form-group'>
                        <input
                          id='contact-name'
                          type='text'
                          name='name'
                          data-constraints='@Required'
                          class='form-control'
                        />
                        <label for='contact-name' class='form-label'>
                          Name
                        </label>
                      </div>
                    </div>
                    <div class='cell-sm-6 offset-top-30 offset-sm-top-0'>
                      <div class='form-group'>
                        <input
                          id='contact-us-email'
                          type='email'
                          name='email'
                          data-constraints='@Email @Required'
                          class='form-control'
                        />
                        <label for='contact-us-email' class='form-label'>
                          Email
                        </label>
                      </div>
                    </div>
                    <div class='cell-xs-12 offset-top-30'>
                      <div class='form-group'>
                        <input
                          id='contact-phoneNumber'
                          type='text'
                          name='phoneNumber'
                          data-constraints='@Required'
                          class='form-control'
                        />
                        <label for='contact-phoneNumber' class='form-label'>
                          Phone Number
                        </label>
                      </div>
                    </div>
                    <div class='cell-xs-12 offset-top-30'>
                      <div class='form-group'>
                        <div class='textarea-lined-wrap'>
                          <textarea
                            id='contact-message'
                            name='message'
                            // data-constraints='@Required'
                            class='form-control'
                          ></textarea>
                          <label for='contact-message' class='form-label'>
                            Message
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class='cell-xs-8 offset-top-30 offset-xs-top-30 offset-sm-top-60'>
                      <button type='submit' class='btn btn-primary btn-block'>
                        Send
                      </button>
                    </div>
                    <div class='cell-xs-4 offset-top-22 offset-xs-top-30 offset-sm-top-60'>
                      <button
                        type='reset'
                        class='btn btn-silver-outline btn-block'
                      >
                        Reset
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>

        <section
          data-zoom='14'
          data-x='-73.9874068'
          data-y='40.643180'
          data-styles='[{"featureType":"landscape","stylers":[{"hue":"#FFBB00"},{"saturation":43.400000000000006},{"lightness":37.599999999999994},{"gamma":1}]},{"featureType":"road.highway","stylers":[{"hue":"#FFC200"},{"saturation":-61.8},{"lightness":45.599999999999994},{"gamma":1}]},{"featureType":"road.arterial","stylers":[{"hue":"#FF0300"},{"saturation":-100},{"lightness":51.19999999999999},{"gamma":1}]},{"featureType":"road.local","stylers":[{"hue":"#FF0300"},{"saturation":-100},{"lightness":52},{"gamma":1}]},{"featureType":"water","stylers":[{"hue":"#0078FF"},{"saturation":-13.200000000000003},{"lightness":2.4000000000000057},{"gamma":1}]},{"featureType":"poi","stylers":[{"hue":"#00FF6A"},{"saturation":-1.0989010989011234},{"lightness":11.200000000000017},{"gamma":1}]}]'
          class='rd-google-map rd-google-map__model'
        >
          <ul class='map_locations'>
            <li data-y='40.643180' data-x='-73.9874068'>
              <p>2130 Fulton Street San Francisco, CA 94117-1080 USA</p>
            </li>
          </ul>
        </section>
        <Footer />
      </div>
    );
  }
}

export default ContactUs;
